import { useMemo, FC } from 'react'
import { graphql } from 'relay-runtime'
import { BestBuyPrices_cemetery$key } from '@/components/BestBuyPrices/__generated__/BestBuyPrices_cemetery.graphql'
import { useFragment } from 'react-relay/hooks'
import { Section } from '@/components/Section/Section'
import { SectionTypePriceItem } from '@/components/Prices/SectionTypePriceItem'
import { isKoei, makeItems, makeTitle } from './dependencies'
import { Button } from '@lifedot/atoms/Button'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { CallToAction } from '../CallToAction'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { CtaSection } from '@/components/CtaSection/CtaSection'
import { noticeForPrice } from '@lifedot/constants/noticeForPrice'

const styles = {
  title: css({
    borderLeft: `4px solid ${palette.main}`,
    paddingLeft: 12,
    marginBottom: 8
  }),
  link: css({
    display: 'block',
    margin: '0 auto',
    maxWidth: 400,
    width: '90%',
    textDecoration: 'none'
  }),
  note: css({
    color: palette.gray6
  })
}

type Props = {
  cemetery: BestBuyPrices_cemetery$key
}

export const BestBuyPrices: FC<Props> = ({ cemetery }) => {
  const data = useFragment(fragment, cemetery)
  const title = makeTitle(data.name, data.requestable, isKoei(data.choices))
  const items = useMemo(() => makeItems(data), [data])

  if (items.length < 1) return null

  return (
    <>
      <Section title={title} id="a-price">
        <AddMarginWrapper spacing={5}>
          {items.map((item) => (
            <section key={item.sectionName}>
              <h3 css={[typography.textL, styles.title]}>{item.sectionName}</h3>
              <SectionTypePriceItem {...item} />
            </section>
          ))}
          <Link href={router.price(data.cemeteryId)} passHref prefetch={false}>
            <a css={styles.link}>
              <Button
                mainText={
                  <span css={typography.textL}>
                    価格・費用をすべて見る
                    <small>（{data.price_and_specs.length}件）</small>
                  </span>
                }
                variant="secondary"
                as="div"
              />
            </a>
          </Link>
          <p css={[typography.textS, styles.note]}>※{noticeForPrice}</p>
        </AddMarginWrapper>
      </Section>
      <CtaSection>
        <CallToAction
          cemetery={data}
          eventTrack
          eventLabelIndex="price_"
          hideStockListButton
        />
      </CtaSection>
    </>
  )
}

const fragment = graphql`
  fragment BestBuyPrices_cemetery on Cemetery {
    cemeteryId: id
    name
    choices {
      code
    }
    requestable
    price_and_specs {
      specsId: id
    }
    best_price_by_category {
      normal {
        priceId: id
        name
        category_code
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        price4
        price4_name
        price4_unit
        purchase_price_option
        price3
        price3_unit
        simulated_price3
        lot_area
        lot_area_spec
        capacity
        spec
        image {
          alt
          url
        }
      }
      nokotsu {
        priceId: id
        name
        category_code
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        price4
        price4_name
        price4_unit
        purchase_price_option
        price3
        price3_unit
        simulated_price3
        lot_area
        lot_area_spec
        capacity
        spec
        image {
          alt
          url
        }
      }
      jumoku {
        priceId: id
        name
        category_code
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        price4
        price4_name
        price4_unit
        purchase_price_option
        price3
        price3_unit
        simulated_price3
        lot_area
        lot_area_spec
        capacity
        spec
        image {
          alt
          url
        }
      }
      eitai_kuyo {
        priceId: id
        name
        category_code
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        price4
        price4_name
        price4_unit
        purchase_price_option
        price3
        price3_unit
        simulated_price3
        lot_area
        lot_area_spec
        capacity
        spec
        image {
          alt
          url
        }
      }
      other {
        priceId: id
        name
        category_code
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        price4
        price4_name
        price4_unit
        purchase_price_option
        price3
        price3_unit
        simulated_price3
        lot_area
        lot_area_spec
        capacity
        spec
        image {
          alt
          url
        }
      }
    }
    ...CallToAction_cemetery
  }
`
