import { FC } from 'react'
import { markup } from '@lifedot/utils'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { DetailStructuredData_cemetery$key } from './__generated__/DetailStructuredData_cemetery.graphql'
import {
  makeArticleJson,
  makeCemeteryJson,
  makeDescription,
  makeLocalBusinessJson,
  makeServiceJson,
  makeTitle
} from './dependencies'
import { useRouter } from 'next/router'
import {
  OrganizationJsonLd,
  WebPageJsonLd
} from '@lifedot/components/StructuredData'

interface DetailStructuredDataProps {
  cemetery: DetailStructuredData_cemetery$key
}

export const DetailStructuredData: FC<DetailStructuredDataProps> = ({
  cemetery
}) => {
  const data = useFragment(query, cemetery)
  const { asPath } = useRouter()
  const description = makeDescription(data)
  const cemeteryJsonLd = makeCemeteryJson(data)
  const articleJsonLd = makeArticleJson(
    data,
    asPath,
    makeTitle(data).replace(' | 資料請求【ライフドット公式】', ''),
    description
  )
  const localBusinessJsonLd = makeLocalBusinessJson(data)
  const serviceJsonLd = makeServiceJson(data)

  return (
    <>
      <OrganizationJsonLd data-cy="structured-data-organization" />
      <WebPageJsonLd
        description={description}
        data-cy="structured-data-web-page"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={markup(cemeteryJsonLd)}
        data-cy="structured-data-cemetery"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={markup(articleJsonLd)}
        data-cy="structured-data-article"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={markup(localBusinessJsonLd)}
        data-cy="structured-data-local-business"
      />
      {serviceJsonLd && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={markup(serviceJsonLd)}
          data-cy="structured-data-service"
        />
      )}
    </>
  )
}

const query = graphql`
  fragment DetailStructuredData_cemetery on Cemetery {
    cemeteryId: id
    name
    created_at
    updated_at
    address
    details {
      postal_code
    }
    choices {
      name
      code
    }
    review_summary {
      total_score
      count
    }
    requestable
    location {
      lat
      lon
    }
    main_image {
      path
    }
    section_types {
      code
      name
    }
    prefecture {
      name
    }
    city {
      name
    }
    nearest_station {
      name
    }
    price_and_specs {
      price1
    }
  }
`
