import { FC } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { Abouts_abouts$key } from './__generated__/Abouts_abouts.graphql'
import { DirectorComment } from './DirectorComment'
import { Details } from './Details'
import { Hakajimai } from './Hakajimai'
import { Applications } from './Applications'
import { ServiceReportFeatures } from './ServiceReportFeatures'
import { RelatedPost } from './RelatedPost'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { Section } from '@/components/Section/Section'
import { CallToAction } from '@/components/CallToAction'
import { ServiceReportFeatures_features$key } from './__generated__/ServiceReportFeatures_features.graphql'
import { Abouts_posts$key } from './__generated__/Abouts_posts.graphql'
import { RelatedCemeteries } from './RelatedCemeteries'
import { CtaSection } from '@/components/CtaSection/CtaSection'

interface AboutsProps {
  abouts: Abouts_abouts$key
  features: ServiceReportFeatures_features$key
  posts: Abouts_posts$key
}

export const Abouts: FC<AboutsProps> = ({ abouts, features, posts }) => {
  const cemeteryData = useFragment(cemeteryFragment, abouts)
  const postData = useFragment(postFragment, posts)
  const { name } = cemeteryData

  return (
    <>
      <Section title={`${name}の特徴`} id="a-about">
        <AddMarginWrapper spacing={7}>
          <DirectorComment comment={cemeteryData} />
          <RelatedPost posts={postData} />
          <ServiceReportFeatures features={features} />
          <Applications applications={cemeteryData} />
          <Hakajimai name={name} />
          <RelatedCemeteries cemetery={cemeteryData} />
          <Details details={cemeteryData} />
        </AddMarginWrapper>
      </Section>
      <CtaSection>
        <CallToAction
          cemetery={cemeteryData}
          eventTrack
          eventLabelIndex="about_"
          hideStockListButton
        />
      </CtaSection>
    </>
  )
}

const cemeteryFragment = graphql`
  fragment Abouts_abouts on Cemetery {
    ...Details_details
    ...Applications_applications
    ...DirectorComment_comment
    ...RelatedCemeteries_cemetery
    ...CallToAction_cemetery
    name
  }
`

const postFragment = graphql`
  fragment Abouts_posts on PostResult {
    ...RelatedPost_posts
  }
`
