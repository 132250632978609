import { FC } from 'react'
import { css } from '@emotion/react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { RelatedCemeteries_cemetery$key } from './__generated__/RelatedCemeteries_cemetery.graphql'
import { router } from '@lifedot/router'
import { typography } from '@lifedot/styles/typography'
import { List, ListItem } from '@lifedot/atoms/List'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const styles = {
  root: css({
    ul: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 8,
      marginTop: 8,
      [mq('sp')]: {
        gridTemplateColumns: '1fr'
      }
    }
  }),
  icon: css({
    color: palette.main,
    fontSize: '12px !important'
  })
}

interface RelatedCemeteriesProps {
  cemetery: RelatedCemeteries_cemetery$key
}

export const RelatedCemeteries: FC<RelatedCemeteriesProps> = ({ cemetery }) => {
  const data = useFragment(fragment, cemetery)

  if (data.related_cemeteries.length < 1) return null
  return (
    <div css={styles.root}>
      <h3 css={typography.textXL}>{data.name}に関連するお墓情報</h3>
      <List icon={<FiberManualRecordIcon css={styles.icon} />}>
        {data.related_cemeteries.map(({ cemeteryId, name }) => (
          <ListItem key={cemeteryId}>
            <a href={router.cemetery(cemeteryId)}>{name}</a>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

const fragment = graphql`
  fragment RelatedCemeteries_cemetery on Cemetery {
    related_cemeteries {
      cemeteryId: id
      name
    }
    name
  }
`
