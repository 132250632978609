import { FC } from 'react'
import { css } from '@emotion/react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { DirectorComment_comment$key } from './__generated__/DirectorComment_comment.graphql'
import Image from 'next/image'
import { palette } from '@lifedot/styles/palette'
import { getDirector } from '@lifedot/utils'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'

const styles = {
  wrap: css({
    display: 'flex',
    [mq('sp')]: {
      flexDirection: 'column'
    }
  }),
  director: css({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    textAlign: 'center',
    [mq('sp')]: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'left'
    }
  }),
  img: css({
    [mq('sp')]: {
      width: 64,
      marginRight: 16
    }
  }),
  title: css({
    fontWeight: 'bold'
  }),
  name: css({
    borderBottom: `1px dotted ${palette.main03}`,
    padding: '0 4px',
    display: 'inline-block'
  }),
  comment: css({
    border: `2px solid ${palette.gray2}`,
    borderRadius: 4,
    padding: 20,
    marginLeft: 20,
    position: 'relative',
    [mq('sp')]: {
      margin: '8px 0 0 0'
    },
    '::before, ::after': {
      content: '""',
      position: 'absolute',
      top: 40,
      border: '8px solid transparent',
      [mq('sp')]: {
        transform: 'rotate(90deg)'
      }
    },
    '::before': {
      left: -22,
      borderRight: `12px solid ${palette.gray2}`,
      [mq('sp')]: {
        top: -18,
        left: 20
      }
    },
    '::after': {
      left: -18,
      borderRight: `12px solid ${palette.white}`,
      [mq('sp')]: {
        top: -14,
        left: 20
      }
    }
  })
}

interface DirectorCommentProps {
  comment: DirectorComment_comment$key
}

export const DirectorComment: FC<DirectorCommentProps> = ({ comment }) => {
  const { director_comment: directorComment, cemeteryId } = useFragment(
    fragment,
    comment
  )
  if (!directorComment) return null

  return (
    <div css={styles.wrap}>
      <div css={styles.director}>
        <figure css={styles.img}>
          <Image
            src={getDirector(cemeteryId).iconImagePath}
            width={120}
            height={120}
            alt="お墓ディレクターの画像"
          />
        </figure>
        <div css={styles.title}>
          お墓ディレクター
          <br />
          <div css={styles.name}>
            <Image
              src={getDirector(cemeteryId).nameImagePath}
              width={75}
              height={25}
              alt="お墓ディレクターの画像"
            />
          </div>
          が紹介！
        </div>
      </div>
      <p css={[typography.textM, styles.comment]}>{directorComment}</p>
    </div>
  )
}

const fragment = graphql`
  fragment DirectorComment_comment on Cemetery {
    cemeteryId: id
    director_comment
  }
`
