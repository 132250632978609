import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import dayjs from 'dayjs'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { LastUpdated_date$key } from './__generated__/LastUpdated_date.graphql'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  date: css({
    color: palette.gray6,
    textAlign: 'right',
    [mq('sp')]: {
      padding: '0 8px'
    }
  })
}

interface LastUpdatedProps {
  cemetery: LastUpdated_date$key
}

export const LastUpdated: FC<LastUpdatedProps> = ({ cemetery }) => {
  const { updated_at } = useFragment(fragment, cemetery)
  return (
    <p css={[typography.textS, styles.date]}>
      最終更新日：{dayjs(updated_at).format('YYYY/M/DD')}
    </p>
  )
}

const fragment = graphql`
  fragment LastUpdated_date on Cemetery {
    updated_at
  }
`
