/**
 * @generated SignedSource<<598ce55eaf7e72c5c4f03f049c308ebd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type DetailSeoHead_cemetery$data = {
  readonly cemeteryId: number
  readonly name: string
  readonly prefecture: {
    readonly name: string
  }
  readonly city: {
    readonly name: string
  }
  readonly nearest_station: {
    readonly name: string | null
  }
  readonly choices: ReadonlyArray<{
    readonly attribute_code: string
    readonly code: string
    readonly name: string
  }>
  readonly price_and_specs: ReadonlyArray<{
    readonly priceAndSpecId: number
  }>
  readonly main_image: {
    readonly path: string | null
  } | null
  readonly ' $fragmentType': 'DetailSeoHead_cemetery'
}
export type DetailSeoHead_cemetery$key = {
  readonly ' $data'?: DetailSeoHead_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'DetailSeoHead_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v1 = [v0 /*: any*/]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'DetailSeoHead_cemetery',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: v1 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryCity',
        kind: 'LinkedField',
        name: 'city',
        plural: false,
        selections: v1 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryNearestStation',
        kind: 'LinkedField',
        name: 'nearest_station',
        plural: false,
        selections: v1 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'choices',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'attribute_code',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'code',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPriceAndSpec',
        kind: 'LinkedField',
        name: 'price_and_specs',
        plural: true,
        selections: [
          {
            alias: 'priceAndSpecId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryImage',
        kind: 'LinkedField',
        name: 'main_image',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'path',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = '6734e6e6638426b9d4fdf2dfece182cc'

export default node
