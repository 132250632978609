/**
 * @generated SignedSource<<6dbfbf5b5c40625ad780f5ea5b183bec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ImageCarousel_cemetery$data = {
  readonly name: string
  readonly images: ReadonlyArray<{
    readonly path: string | null
    readonly alt: string | null
    readonly active: boolean
  }>
  readonly ' $fragmentType': 'ImageCarousel_cemetery'
}
export type ImageCarousel_cemetery$key = {
  readonly ' $data'?: ImageCarousel_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'ImageCarousel_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ImageCarousel_cemetery',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryImage',
      kind: 'LinkedField',
      name: 'images',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'path',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'alt',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'active',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '4ca12c211a5fa86513a92460c496ea02'

export default node
