/**
 * @generated SignedSource<<259da739c60c44c29df9f5882a491db3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type AccessMap_access$data = {
  readonly ' $fragmentSpreads': FragmentRefs<'Methods_access'>
  readonly ' $fragmentType': 'AccessMap_access'
}
export type AccessMap_access$key = {
  readonly ' $data'?: AccessMap_access$data
  readonly ' $fragmentSpreads': FragmentRefs<'AccessMap_access'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'AccessMap_access',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'Methods_access'
    }
  ],
  type: 'ServiceReportResult',
  abstractKey: null
}

;(node as any).hash = '90c23162e458722ba68ae2ac0f316107'

export default node
