/**
 * @generated SignedSource<<fc663052a3fcd87a28d027261151baef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PurchaseFlow_posts$data = {
  readonly total: number
  readonly ' $fragmentType': 'PurchaseFlow_posts'
}
export type PurchaseFlow_posts$key = {
  readonly ' $data'?: PurchaseFlow_posts$data
  readonly ' $fragmentSpreads': FragmentRefs<'PurchaseFlow_posts'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'PurchaseFlow_posts',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'total',
      storageKey: null
    }
  ],
  type: 'PostResult',
  abstractKey: null
}

;(node as any).hash = '5dec5d3f66a6b210ba633cf84a41c209'

export default node
