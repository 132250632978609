/**
 * @generated SignedSource<<da28ac8b39223f6d7dd75a977104a71e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Methods_access$data = {
  readonly items: ReadonlyArray<{
    readonly content_text: string
    readonly title: string
  }>
  readonly ' $fragmentType': 'Methods_access'
}
export type Methods_access$key = {
  readonly ' $data'?: Methods_access$data
  readonly ' $fragmentSpreads': FragmentRefs<'Methods_access'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Methods_access',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'ServiceReport',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'content_text',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'title',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'ServiceReportResult',
  abstractKey: null
}

;(node as any).hash = '64e0aa6b3724b64b8d395eb97366a68c'

export default node
