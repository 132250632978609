import { FC } from 'react'
import { css } from '@emotion/react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { Reviews_reviews$key } from './__generated__/Reviews_reviews.graphql'
import { typography } from '@lifedot/styles/typography'
import { Button } from '@lifedot/atoms/Button'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { router } from '@lifedot/router'
import { Section } from '../Section/Section'
import { CallToAction } from '../CallToAction'
import { ReviewOverview } from './ReviewOverview/ReviewOverview'
import { Voices } from './Voices/Voices'
import Link from 'next/link'
import { CtaSection } from '@/components/CtaSection/CtaSection'

const styles = {
  overviewWrap: css({
    width: '75%',
    margin: 'auto'
  })
}
interface ReviewsProps {
  reviews: Reviews_reviews$key
}

export const Reviews: FC<ReviewsProps> = ({ reviews }) => {
  const data = useFragment(fragment, reviews)
  const { cemeteryId, name, review_summary } = data
  const moreLink = router.review(cemeteryId, review_summary.count)
  const voiceSize = 1

  if (!review_summary.count) return null
  return (
    <>
      <Section id="a-Review" title={`${name}の口コミ・評判`}>
        <AddMarginWrapper spacing={7}>
          <div css={styles.overviewWrap}>
            <ReviewOverview summary={data} />
          </div>
          <Voices reviews={data} size={voiceSize} />
          {review_summary.count > voiceSize && <MoreLink href={moreLink} />}
        </AddMarginWrapper>
      </Section>
      <CtaSection>
        <CallToAction
          cemetery={data}
          eventTrack
          eventLabelIndex="review_"
          hideStockListButton
        />
      </CtaSection>
    </>
  )
}

const fragment = graphql`
  fragment Reviews_reviews on Cemetery {
    ...ReviewOverview_summary
    ...Voices_reviews
    ...CallToAction_cemetery
    name
    cemeteryId: id
    review_summary {
      count
    }
  }
`

const linkStyles = {
  root: css({
    textDecoration: 'none',
    maxWidth: 400,
    width: '90%',
    display: 'block',
    margin: 'auto'
  })
}

interface MoreLinkProps {
  href: string
}

const MoreLink: FC<MoreLinkProps> = ({ href }) => {
  return (
    <Link href={href} passHref prefetch={false}>
      <a css={linkStyles.root}>
        <Button
          variant="secondary"
          mainText={<span css={typography.textL}>口コミをすべて見る</span>}
          as="div"
        />
      </a>
    </Link>
  )
}
