import { ComponentProps, FC, isValidElement, ReactNode } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { ImageCarousel_cemetery$key } from './__generated__/ImageCarousel_cemetery.graphql'
import { Carousel } from 'react-responsive-carousel'
import Image, { ImageProps } from 'next/image'
import { css } from '@emotion/react'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import { makeItems } from './dependencies'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { carouselStyles } from './styles'
import { mq } from '@lifedot/styles/mediaQuery'
import { rgba } from 'emotion-rgba'

const styles = {
  img: css({
    fontSize: 0,
    [mq('pc')]: {
      maxHeight: 594
    }
  }),
  arrow: css({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    background: 'none',
    color: palette.white
  }),
  next: css({
    right: 0
  }),
  prev: css({
    left: 0
  }),
  caption: css({
    backgroundColor: rgba(palette.black, 0.8),
    position: 'absolute',
    bottom: 0,
    color: palette.white,
    width: '100%',
    padding: 8,
    textAlign: 'left'
  })
}

interface ImageCarouselProps {
  cemetery: ImageCarousel_cemetery$key
}

const MAX_PRIORITY_LOAD = 20

export const ImageCarousel: FC<ImageCarouselProps> = ({ cemetery }) => {
  const { name, images } = useFragment(fragment, cemetery)

  const items = makeItems({ images })

  return (
    <div css={carouselStyles}>
      <Carousel
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop
        renderThumbs={renderThumbs}
        thumbWidth={85}
        renderArrowNext={(clickHandler, hasNext) => (
          <button
            onClick={clickHandler}
            css={[styles.arrow, styles.next]}
            aria-label="次の画像を見る"
            style={hasNext ? {} : { display: 'none' }}
          >
            <KeyboardArrowRight style={{ fontSize: 40 }} />
          </button>
        )}
        renderArrowPrev={(clickHandler, hasPrev) => (
          <button
            onClick={clickHandler}
            css={[styles.arrow, styles.prev]}
            aria-label="前の画像を見る"
            style={hasPrev ? {} : { display: 'none' }}
          >
            <KeyboardArrowLeft style={{ fontSize: 40 }} />
          </button>
        )}
      >
        {items.map(({ alt, path }, index) => {
          return (
            <CarouselInnerImage
              key={path}
              src={path}
              alt={alt ?? name}
              width={880}
              height={660}
              objectFit="cover"
              priority={index < MAX_PRIORITY_LOAD}
            />
          )
        })}
      </Carousel>
    </div>
  )
}

const fragment = graphql`
  fragment ImageCarousel_cemetery on Cemetery {
    name
    images {
      path
      alt
      active
    }
  }
`

const CarouselInnerImage: FC<ImageProps> = (props) => {
  return (
    <div css={styles.img}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <Image {...props} />
      {props.alt ? (
        <p css={[typography.textS, styles.caption]}>{props.alt}</p>
      ) : null}
    </div>
  )
}

const thumbStyles = {
  root: css({
    p: {
      display: 'none'
    }
  })
}

type ThumbWrapperProps = {
  children: ReactNode
}

const ThumbWrapper: FC<ThumbWrapperProps> = ({ children }) => {
  return <div css={thumbStyles.root}>{children}</div>
}

const renderThumbs: ComponentProps<typeof Carousel>['renderThumbs'] = (
  children
) =>
  children.length > 1
    ? children.map((child, index) => {
        if (isValidElement<ImageProps>(child)) {
          const { src, alt, priority } = child.props
          return (
            <ThumbWrapper key={index}>
              <Image
                src={src}
                width={84}
                height={62}
                quality={5}
                alt={alt}
                priority={priority}
                objectFit="cover"
              />
            </ThumbWrapper>
          )
        }
        return child
      })
    : []
