/**
 * @generated SignedSource<<54a6eb50282db9f9a196717a81f36199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type FrequentlyAskedQuestions_cemetery$data = {
  readonly name: string
  readonly ' $fragmentSpreads': FragmentRefs<'useFaqs_faqs'>
  readonly ' $fragmentType': 'FrequentlyAskedQuestions_cemetery'
}
export type FrequentlyAskedQuestions_cemetery$key = {
  readonly ' $data'?: FrequentlyAskedQuestions_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'FrequentlyAskedQuestions_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'FrequentlyAskedQuestions_cemetery',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'useFaqs_faqs'
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = 'b6caf09d52534b8461c26551771f4345'

export default node
