import { FC, useCallback, useState } from 'react'
import { Tab, Tabs, TabPanel } from '@lifedot/atoms/Tabs'
import { makePurchaseFlowTypes } from './dependencies'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { SectionTypeCode } from '@lifedot/constants/searchChoices'
import { NormalFlow } from './NormalFlow'
import { OtherFlow } from './OtherFlow'
import { PurchaseFlow_posts$key } from './__generated__/PurchaseFlow_posts.graphql'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { PurchaseFlow_cemetery$key } from '@/components/PurchaseFlow/__generated__/PurchaseFlow_cemetery.graphql'
import { Section } from '../Section/Section'

const styles = {
  content: css({
    marginTop: 8,
    '[aria-selected="false"]': {
      backgroundColor: palette.gray1
    }
  })
}

interface PurchaseFlowProps {
  posts: PurchaseFlow_posts$key
  cemetery: PurchaseFlow_cemetery$key | null
}

export const PurchaseFlow: FC<PurchaseFlowProps> = ({ posts, cemetery }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const handleTab = useCallback((value: number) => {
    setCurrentTab(value)
  }, [])
  const { total: postCount } = useFragment(fragment, posts)
  const cemeteryData = useFragment(cemeteryFragment, cemetery)
  if (!cemeteryData) return null
  const types = makePurchaseFlowTypes([...cemeteryData.section_types])
  if (types.length === 0) return null

  return (
    <Section>
      <h2 css={typography.headingXS}>墓所購入からご納骨までの流れ</h2>
      <div css={styles.content}>
        {types.length > 1 ? (
          <>
            <Tabs handleTab={handleTab} value={currentTab}>
              {types.map(({ type, name }) => (
                <Tab label={name} key={type} />
              ))}
            </Tabs>
            {types.map(({ type }, index) => (
              <TabPanel index={index} value={currentTab} key={type}>
                {type === SectionTypeCode.NORMAL ? (
                  <NormalFlow />
                ) : (
                  <OtherFlow hasPosts={postCount > 0} />
                )}
              </TabPanel>
            ))}
          </>
        ) : types[0]?.type === SectionTypeCode.NORMAL ? (
          <NormalFlow />
        ) : (
          <OtherFlow hasPosts={postCount > 0} />
        )}
      </div>
    </Section>
  )
}

const fragment = graphql`
  fragment PurchaseFlow_posts on PostResult {
    total
  }
`

const cemeteryFragment = graphql`
  fragment PurchaseFlow_cemetery on Cemetery {
    section_types {
      code
      name
    }
  }
`
