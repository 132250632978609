/**
 * @generated SignedSource<<af31fc8545f403eafce612d648bca9d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type useFaqs_price$data = {
  readonly cemeteryId: number
  readonly choices: ReadonlyArray<{
    readonly code: string
  }>
  readonly name: string
  readonly price_and_specs: ReadonlyArray<{
    readonly name: string | null
    readonly category_code: string
    readonly purchase_price: number | null
    readonly calculated_purchase_price: number | null
    readonly price1: number | null
  }>
  readonly ' $fragmentType': 'useFaqs_price'
}
export type useFaqs_price$key = {
  readonly ' $data'?: useFaqs_price$data
  readonly ' $fragmentSpreads': FragmentRefs<'useFaqs_price'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'useFaqs_price',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'choices',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'code',
            storageKey: null
          }
        ],
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPriceAndSpec',
        kind: 'LinkedField',
        name: 'price_and_specs',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'category_code',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'purchase_price',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'calculated_purchase_price',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'price1',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = '07878b7940b9d99b1ca7137c87196f75'

export default node
