import { FC } from 'react'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { Step, Steps } from './Step'

const styles = {
  alert: css({
    color: palette.alert
  }),
  last: css({
    border: `1px solid ${palette.alert}`,
    borderRadius: 4,
    padding: '4px 8px',
    color: palette.alert,
    textAlign: 'center'
  })
}

export const NormalFlow: FC = () => {
  return (
    <Steps
      head={
        <p>
          お問い合わせから<b css={styles.alert}>最短2~3ヵ月</b>
          でお墓の建立・ご納骨いただけます。
        </p>
      }
      caption={
        <>
          お墓の購入からご納骨まで、詳しい流れを知りたい方は
          <Link href={router.post('ohaka-konryu')} passHref prefetch={false}>
            <a target="_blank">
              「5ステップで解説！お墓を建立する流れ～時期や注意点も紹介～」
            </a>
          </Link>
          の記事もご覧ください。
        </>
      }
    >
      <Step
        step={1}
        image={{
          path: 'https://s3.lifedot.jp/uploads/ending/path/67766/img_purchaseProcess_request.png',
          alt: 'デバイスのイラスト'
        }}
        title="問い合わせる"
        supplement="霊園資料や最新情報をお届けします。"
      />
      <Step
        step={2}
        image={{
          path: 'https://s3.lifedot.jp/uploads/ending/path/67764/img_purchaseProcess_visit.png',
          alt: '見学に向かう人のイラスト'
        }}
        title="見学に行く"
        supplement="実際に見学し、気に入ったものを選びます。"
      />
      <Step
        step={3}
        image={{
          path: 'https://s3.lifedot.jp/uploads/ending/path/67763/img_purchaseProcess_agreement.png',
          alt: '申し込み書類のイラスト'
        }}
        title="お申し込み"
        supplement="墓地に合わせて墓石のデザインを決めます。"
      />
      <Step
        step={4}
        image={{
          path: 'https://s3.lifedot.jp/uploads/ending/path/67765/img_purchaseProcess_complete.png',
          alt: 'お墓のイラスト'
        }}
        title="建立・ご納骨"
      >
        <p css={[typography.textS, styles.last]}>
          ここまで
          <br />
          <b css={typography.textL}>最短2~3ヶ月</b>
        </p>
      </Step>
    </Steps>
  )
}
