/**
 * @generated SignedSource<<1da5f785654cfd853312074f0dc4822c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PurchaseFlow_cemetery$data = {
  readonly section_types: ReadonlyArray<{
    readonly code: string
    readonly name: string
  }>
  readonly ' $fragmentType': 'PurchaseFlow_cemetery'
}
export type PurchaseFlow_cemetery$key = {
  readonly ' $data'?: PurchaseFlow_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'PurchaseFlow_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'PurchaseFlow_cemetery',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryChoice',
      kind: 'LinkedField',
      name: 'section_types',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'code',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '31e1f67bad1c83f867fa467f30049d36'

export default node
