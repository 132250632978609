import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { useFaqs_successor$key } from './__generated__/useFaqs_successor.graphql'
import { router } from '@lifedot/router'
import {
  AttributeCode,
  FeatureCode,
  SectionTypeCode,
  sectionTypes
} from '@lifedot/constants/searchChoices'
import { priceFormatter } from '@lifedot/utils'
import { useFaqs_cemeteryHas$key } from './__generated__/useFaqs_cemeteryHas.graphql'
import { useFaqs_price$key } from './__generated__/useFaqs_price.graphql'
import { useFaqs_faqs$key } from './__generated__/useFaqs_faqs.graphql'
import { useMemo } from 'react'

export interface UseFaqsArgs {
  faqs: useFaqs_faqs$key
}

interface Faqs {
  question: string
  answer: string
  category?: string
}

export const useFaqs = ({ faqs }: UseFaqsArgs): Array<Faqs> => {
  const data = useFragment(fragment, faqs)
  return [
    ...(useFaqCemeteryHas({ cemeteryHas: data }) ?? []),
    { ...useFaqPrice({ price: data }), category: '費用' },
    { ...useFaqSuccessor({ successor: data }), category: '永代供養' }
  ].filter((faq): faq is Faqs => Boolean(faq.question && faq.answer))
}

const fragment = graphql`
  fragment useFaqs_faqs on Cemetery {
    ...useFaqs_price
    ...useFaqs_cemeteryHas
    ...useFaqs_successor
  }
`

interface CemeteryHas {
  cemeteryHas: useFaqs_cemeteryHas$key
}

export const useFaqCemeteryHas = ({
  cemeteryHas
}: CemeteryHas): Array<Omit<Faqs, 'category'>> | null => {
  const { faqs } = useFragment(fragmentCemetery, cemeteryHas)

  return useMemo(() => {
    if (faqs.length < 1) return null
    return faqs.map(({ answer, question }) => ({ question, answer }))
  }, [faqs])
}

const fragmentCemetery = graphql`
  fragment useFaqs_cemeteryHas on Cemetery {
    faqs {
      question
      answer
    }
  }
`

interface Price {
  price: useFaqs_price$key
}

export const useFaqPrice = ({
  price
}: Price): Omit<Faqs, 'category'> | null => {
  const {
    name,
    cemeteryId,
    price_and_specs: prices,
    choices
  } = useFragment(fragmentPrice, price)

  return useMemo(() => {
    const havingCodes = choices.map(({ code }) => code)
    const sectionType = sectionTypes
      .filter(({ code }) => code !== SectionTypeCode.NORMAL)
      .find(({ code }) => havingCodes.includes(code))
    if (!sectionType) return null
    const targetPrices = prices
      .filter(({ category_code }) => category_code === sectionType.code)
      .sort(
        (a, b) =>
          (a.purchase_price ||
            a.calculated_purchase_price ||
            a.price1 ||
            Infinity) -
          (b.purchase_price ||
            b.calculated_purchase_price ||
            b.price1 ||
            Infinity)
      )
    const [targetPrice] = targetPrices
    const display =
      targetPrice?.purchase_price ||
      targetPrice?.calculated_purchase_price ||
      targetPrice?.price1

    return {
      question: priceQuestion
        .replace(/#cemetery_name/g, name)
        .replace(/#section_type/g, sectionType.name),
      answer: (!targetPrice
        ? hasNoPriceText
        : display
        ? hasDisplayPriceText
        : hasPriceText
      )
        .replace(/#cemetery_link/g, router.cemetery(cemeteryId))
        .replace(/#cemetery_price_link/g, router.price(cemeteryId))
        .replace(/#cemetery_name/g, name)
        .replace(/#section_type/g, sectionType.name)
        .replace(/#price_count/g, String(targetPrices?.length))
        .replace(/#price_name/g, targetPrice?.name ?? '')
        .replace(/#price/g, priceFormatter(display ?? 0) as string)
    }
  }, [cemeteryId, choices, name, prices])
}

const priceQuestion =
  '❓#cemetery_nameで#section_typeを購入する場合の費用は、いくら位かかりますか？'

const hasPriceText = `
#cemetery_nameの料金、<b>値段の内訳や、空き区画の状況は、
<a href="#cemetery_price_link">#cemetery_nameの価格情報</a>をご覧ください。</b><br /><br />
#cemetery_nameの#section_typeの費用プランは#price_count種類です。<br />
あなたに合ったプランを探してみてください。
`

const hasDisplayPriceText = `
#cemetery_nameの#price_name料金は、#price円からご案内しています。<br />
<b>値段の内訳や、空き区画の状況は、
<a href="#cemetery_price_link">#cemetery_nameの価格情報</a>をご覧ください。</b><br /><br />
#cemetery_nameの#section_typeの費用プランは#price_count種類です。<br />
あなたに合ったプランを探してみてください。
`

const hasNoPriceText = `
#section_typeの料金を知りたい方は、<a href="#cemetery_link">#cemetery_name</a>
のページよりお問い合わせください。<br /><br />
<b>【お気軽にご相談ください】</b><br />
・値段の内訳や、空き区画状況のご質問<br />
・予算に合った納骨堂のご紹介<br />
・お墓の選び方のご相談
`

const fragmentPrice = graphql`
  fragment useFaqs_price on Cemetery {
    cemeteryId: id
    choices {
      code
    }
    name
    price_and_specs {
      name
      category_code
      purchase_price
      calculated_purchase_price
      price1
    }
  }
`

interface Successor {
  successor: useFaqs_successor$key
}

export const useFaqSuccessor = ({
  successor
}: Successor): Omit<Faqs, 'category'> => {
  const { flags, name, cemeteryId, city, prefecture, choices } = useFragment(
    fragmentSuccessor,
    successor
  )

  return useMemo(() => {
    const havingCodes = choices.map(({ code }) => code)
    const types = [
      SectionTypeCode.JUMOKU,
      SectionTypeCode.NOKOTSU,
      SectionTypeCode.EITAI_KUYO
    ]
      .filter((code) => havingCodes.includes(code))
      .map(
        (code) =>
          sectionTypes.find((sectionType) => sectionType.code === code)?.name
      )

    return {
      question: successorQuestion.replace(/#cemetery_name/g, name),
      answer: (flags.successor_unnecessary
        ? successorUnnecessaryText
        : successorNecessaryText
      )
        .replace(/#cemetery_link/g, router.cemetery(cemeteryId))
        .replace(/#cemetery_name/g, name)
        .replace(/#city_name/g, city.name)
        .replace(
          /#list_link/g,
          router.list({
            prefectureRoma: prefecture.roma,
            cityId: city.cityId,
            condition: {
              attributeCode: AttributeCode.FEATURE,
              code: FeatureCode.SUCCESSOR_EXISTENCE
            }
          })
        )
        .replace(
          /#additional_text/g,
          `${
            types.length > 0
              ? `特に、${types.join(
                  '・'
                )}は、永代供養を希望される方におすすめのお墓です。<br />`
              : ''
          }`
        )
    }
  }, [
    cemeteryId,
    choices,
    city.cityId,
    city.name,
    flags.successor_unnecessary,
    name,
    prefecture.roma
  ])
}

const successorQuestion =
  '❓#cemetery_nameには、永代供養ができるお墓はありますか？'

const successorNecessaryText = `
<a href="#cemetery_link">#cemetery_name</a>のお墓は、永代供養に対応していませんが、
<b>同じ#city_name内にある、ほかの永代供養のお墓を探すことができます。</b><br />
<a href="#list_link">継承者不要で利用できる#city_name内の永代供養のお墓一覧</a>
をご覧ください。</b><br /><br />
永代供養のお墓は、後継者がいなくても、霊園・墓地が責任をもって供養を行ってくれるので安心です。<br />
お墓の承継者や後継ぎがいない方や、子どもや家族に面倒をかけたくないとお考えの方でもお墓を購入しやすいでしょう。
`

const successorUnnecessaryText = `
#cemetery_nameは、永代供養に対応しています。<br />
#additional_text
<b>永代供養のお墓のプランや費用は、<a href="#cemetery_link">#cemetery_name</a>
のページをご覧いただき、お問い合わせください。</b><br /><br />
後継者がいなくても、#cemetery_nameが責任をもって供養を行ってくれるので安心です。
お墓の承継者や後継ぎがいない方や、子どもや家族に面倒をかけたくないとお考えの方でもお墓を購入しやすいでしょう。
`

const fragmentSuccessor = graphql`
  fragment useFaqs_successor on Cemetery {
    cemeteryId: id
    flags {
      successor_unnecessary
    }
    choices {
      code
    }
    prefecture {
      roma
    }
    city {
      cityId: id
      name
    }
    name
  }
`
