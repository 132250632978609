import { cemeteryPage_indexQuery } from '@/queries/__generated__/cemeteryPage_indexQuery.graphql'
import { initEnvironment } from '@lifedot/relay'
import { GetStaticPaths, GetStaticProps } from 'next'
import { FC } from 'react'
import { fetchQuery } from 'relay-runtime'
import {
  cemeteryPageBuildTargetsQuery,
  cemeteryPagePrepareQuery,
  cemeteryPageQuery
} from '@/queries/cemeteryPage'
import { Overview } from '@/components/Overview'
import { useQuery } from 'relay-hooks/lib/useQuery'
import { NotFound } from '@lifedot/components/NotFound'
import { SingleColumn } from '@lifedot/layout'
import { Reviews } from '@/components/Reviews/Reviews'
import { Abouts } from '@/components/Abouts'
import { cemeteryPage_prepareQuery } from '@/queries/__generated__/cemeteryPage_prepareQuery.graphql'
import { sorts } from '@lifedot/constants/sort'
import { Links } from '@/components/Links/Links'
import { Breadcrumb } from '@/components/Breadcrumb/Breadcrumb'
import { DetailSeoHead } from '@/components/Seo/detail'
import { FrequentlyAskedQuestions } from '@/components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'
import { Anchors } from '@/components/Anchors/Anchors'
import { cemeteryPage_buildTargetsQuery } from '@/queries/__generated__/cemeteryPage_buildTargetsQuery.graphql'
import { EventTracker, useGa4ViewTracking } from '@lifedot/tracking'
import { ThirdPartyAdData } from '@/components/ThirdPartyAdData/ThirdPartyAdData'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { DetailStructuredData } from '@/components/StructuredData/detail/DetailStructuredData'
import { LastUpdated } from '@/components/LastUpdated/LastUpdated'
import { Appearance } from '@/components/Appearance/Appearance'
import { PurchaseFlow } from '@/components/PurchaseFlow'
import { DummyContent } from '@/components/DummyContent'
import { StickyWrapper } from '@lifedot/components/StickyWrapper'
import { revalidate } from '@/utils/revalidate'
import { router } from '@lifedot/router'
import { BestBuyPrices } from '@/components/BestBuyPrices'
import { Banners } from '@/components/Banners/Banners'
import { AccessMap } from '@/components/AccessMap/AccessMap'
import { elementCategories } from '@lifedot/constants/elementCategories'
import { TourSchedule } from '@/components/TourSchedule'
import { SiteDescription } from '@/components/SiteDescription'
import { StickyTelephoneBanner } from '@/components/StickyTelephoneBanner'

const GENERATE_LIMIT = 500

export const getStaticPaths: GetStaticPaths<{
  cemeteryId: string
}> = async () => {
  if (!['production'].includes(process.env.NEXT_PUBLIC_BUILD_ENV ?? ''))
    return {
      paths: [],
      fallback: 'blocking'
    }

  const { environment }: ReturnType<typeof initEnvironment> = initEnvironment()
  let ids: Array<number> = []
  const fetchIds = async (from: number, limit: number) => {
    const res = await fetchQuery<cemeteryPage_buildTargetsQuery>(
      environment,
      cemeteryPageBuildTargetsQuery,
      {
        size: 500,
        from,
        sort: sorts.default
      }
    ).toPromise()
    if (!res) return
    ids = [
      ...ids,
      ...res.cemeteries.items.map(({ cemeteryId }) => cemeteryId)
    ].slice(0, limit)
    if (res.cemeteries.pagination.hasNext && ids.length < limit)
      await fetchIds(from + 500, limit)
  }
  await fetchIds(0, GENERATE_LIMIT)

  const paths = ids.map((id) => ({
    params: { cemeteryId: String(id) }
  }))
  return {
    paths: paths,
    fallback: 'blocking'
  }
}

export const getStaticProps: GetStaticProps<
  CemeteryPageProps,
  { cemeteryId: string }
> = async ({ params }) => {
  const cemeteryId = Number(params?.cemeteryId) ?? NaN

  if (isNaN(cemeteryId)) {
    return {
      notFound: true
    }
  }

  try {
    const { environment, relaySSR }: ReturnType<typeof initEnvironment> =
      initEnvironment()
    const res = await fetchQuery<cemeteryPage_prepareQuery>(
      environment,
      cemeteryPagePrepareQuery,
      {
        cemeteryId
      },
      {}
    ).toPromise()

    if (res?.cemetery?.redirect_to) {
      return {
        redirect: {
          statusCode: 301,
          destination: router.cemetery(res.cemetery.redirect_to)
        },
        revalidate: revalidate()
      }
    }
    if (!res?.cemetery || !res.cemetery.visible) {
      return {
        notFound: true,
        revalidate: revalidate()
      }
    }

    const cityId = res.cemetery.city.cityId
    const prefectureRoma = res.cemetery.prefecture.roma
    const name = res.cemetery.name

    await fetchQuery<cemeteryPage_indexQuery>(
      environment,
      cemeteryPageQuery,
      {
        cemeteryId,
        cityId,
        prefectureRoma
      },
      {}
    ).toPromise()

    const [, relayData] = await relaySSR.getCache()

    const [cacheString, payload] = relayData
    if (!payload.data)
      return {
        notFound: true
      }

    return {
      props: {
        cemeteryId,
        cityId,
        prefectureRoma,
        name,
        relayData:
          !relayData || !('json' in payload)
            ? null
            : [[cacheString, payload.json]]
      },
      revalidate: revalidate()
    }
  } catch (e) {
    if (typeof e === 'object' && e !== null && 'res' in e) {
      if (typeof (e as { res?: unknown }).res === 'object')
        console.log((e as { res?: { errors?: unknown } }).res?.errors)
    } else if (e instanceof Error) console.log(e.message)

    // ページの生成自体は成功したことにしておき、次のアクセスで再生成し直す
    return {
      props: {
        cemeteryId,
        location: {
          lat: 0,
          lon: 0,
          distance: 0
        },
        choices: [],
        cityId: 0,
        prefectureRoma: '',
        name: ''
      },
      revalidate: 1
    }
  }
}

interface CemeteryPageProps extends PriorityCemeteryCondition {
  cemeteryId: number
  prefectureRoma: string
  name: string
}

const CemeteryPage: FC<CemeteryPageProps> = (props) => {
  const { cemeteryId, cityId, prefectureRoma, name } = props
  const { error, data, isLoading } = useQuery<cemeteryPage_indexQuery>(
    cemeteryPageQuery,
    {
      cemeteryId,
      cityId,
      prefectureRoma
    }
  )
  useGa4ViewTracking({
    elementCategory: elementCategories.reienName,
    elementLabel: name
  })

  if (isLoading) return <DummyContent />
  if (error || !data || !data.cemetery) return <NotFound />

  return (
    <>
      <DetailSeoHead cemetery={data.cemetery} />
      <AddMarginWrapper spacing={10}>
        <SingleColumn>
          <Breadcrumb cemetery={data.cemetery} />
          <Overview cemetery={data.cemetery} />
          <AddMarginWrapper spacing={6}>
            <AddMarginWrapper spacing={1}>
              <Appearance cemetery={data.cemetery} />
              <TourSchedule cemetery={data.cemetery} />
              <BestBuyPrices cemetery={data.cemetery} />
              <Abouts
                abouts={data.cemetery}
                features={data.features}
                posts={data.posts}
              />
              <Reviews reviews={data.cemetery} />
              <AccessMap cemetery={data.cemetery} access={data.access} />
              <FrequentlyAskedQuestions cemetery={data.cemetery} />
              <PurchaseFlow cemetery={data.cemetery} posts={data.posts} />
              <LastUpdated cemetery={data.cemetery} />
            </AddMarginWrapper>
            <Links
              cemetery={data.cemetery}
              prefectureCemeteries={data.prefectureCemeteries}
              prefectureCityCemeteries={data.prefectureCityCemeteries}
              conditionWithCounts={data.conditionWithCounts}
              nearbyCities={data.nearbyCities}
            />
            <Banners />
          </AddMarginWrapper>
          <StickyWrapper offset={500} place="top">
            <EventTracker label="anchors_{innerText}" action="click" clone>
              <Anchors cemetery={data.cemetery} />
            </EventTracker>
          </StickyWrapper>
          <StickyTelephoneBanner cemetery={data.cemetery} />
        </SingleColumn>
        <SiteDescription cemetery={data.cemetery} />
      </AddMarginWrapper>
      <DetailStructuredData cemetery={data.cemetery} />
      <ThirdPartyAdData cemetery={data.cemetery} />
    </>
  )
}

export default CemeteryPage

export interface PriorityCemeteryCondition {
  cityId: number
}
