/**
 * @generated SignedSource<<67a5f9a89321df4efb96aec0dc493ee7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime'
export type cemeteryPage_prepareQuery$variables = {
  cemeteryId: number
}
export type cemeteryPage_prepareQuery$data = {
  readonly cemetery: {
    readonly name: string
    readonly redirect_to: string | null
    readonly prefecture: {
      readonly roma: string
    }
    readonly city: {
      readonly cityId: number
    }
    readonly review_summary: {
      readonly count: number
    }
    readonly best_price_by_category: {
      readonly normal: {
        readonly name: string | null
      } | null
      readonly nokotsu: {
        readonly name: string | null
      } | null
      readonly eitai_kuyo: {
        readonly name: string | null
      } | null
      readonly jumoku: {
        readonly name: string | null
      } | null
      readonly other: {
        readonly name: string | null
      } | null
    }
    readonly visible: boolean
  } | null
}
export type cemeteryPage_prepareQuery = {
  variables: cemeteryPage_prepareQuery$variables
  response: cemeteryPage_prepareQuery$data
}

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cemeteryId'
      }
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v2 = [v1 /*: any*/],
    v3 = [
      {
        alias: 'cemetery',
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'cemeteryId'
          }
        ],
        concreteType: 'Cemetery',
        kind: 'LinkedField',
        name: 'getCemetery',
        plural: false,
        selections: [
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'redirect_to',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPrefecture',
            kind: 'LinkedField',
            name: 'prefecture',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'roma',
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryCity',
            kind: 'LinkedField',
            name: 'city',
            plural: false,
            selections: [
              {
                alias: 'cityId',
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryReviewSummary',
            kind: 'LinkedField',
            name: 'review_summary',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'count',
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryBestPriceByCategory',
            kind: 'LinkedField',
            name: 'best_price_by_category',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'normal',
                plural: false,
                selections: v2 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'nokotsu',
                plural: false,
                selections: v2 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'eitai_kuyo',
                plural: false,
                selections: v2 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'jumoku',
                plural: false,
                selections: v2 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'other',
                plural: false,
                selections: v2 /*: any*/,
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'visible',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ]
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'cemeteryPage_prepareQuery',
      selections: v3 /*: any*/,
      type: 'Query',
      abstractKey: null
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'cemeteryPage_prepareQuery',
      selections: v3 /*: any*/
    },
    params: {
      cacheID: '73330c6f5e41bec0eb5516e76bd59905',
      id: null,
      metadata: {},
      name: 'cemeteryPage_prepareQuery',
      operationKind: 'query',
      text: 'query cemeteryPage_prepareQuery(\n  $cemeteryId: Int!\n) {\n  cemetery: getCemetery(id: $cemeteryId) {\n    name\n    redirect_to\n    prefecture {\n      roma\n    }\n    city {\n      cityId: id\n    }\n    review_summary {\n      count\n    }\n    best_price_by_category {\n      normal {\n        name\n      }\n      nokotsu {\n        name\n      }\n      eitai_kuyo {\n        name\n      }\n      jumoku {\n        name\n      }\n      other {\n        name\n      }\n    }\n    visible\n  }\n}\n'
    }
  }
})()

;(node as any).hash = '172685e55122b30d8c7fd83d9da04bb9'

export default node
