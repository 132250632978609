import Link from 'next/link'
import JapaneseHolidays from 'japanese-holidays'
import { Dayjs } from 'dayjs'
import 'dayjs/locale/ja'
import { router } from '@lifedot/router'
import { lifedotCallCenterNumber } from '@lifedot/constants/phoneNumbers'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import ClearIcon from '@material-ui/icons/Clear'
import { typography } from '@lifedot/styles/typography'
import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'

const SATURDAY = 6
const SUNDAY = 0

const styles = {
  date: css(typography.textS, {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 60,
    textDecoration: 'none'
  }),
  dateNum: css({
    textDecoration: 'underline'
  }),
  status: css({
    color: palette.accent02,
    lineHeight: 1,
    fontWeight: 'bold'
  }),
  holidayColor: (day: number) =>
    css({
      color:
        day === SUNDAY
          ? palette.alert
          : day === SATURDAY
          ? palette.merit
          : palette.black
    }),
  notRequest: css({
    color: palette.gray5,
    lineHeight: 1
  })
}

type CellProps = {
  day: Dayjs
  showMonth: boolean
  cemeteryId: number
}

// TODO ３つのファイル(TelCell.tsx,WeekCell.tsx,DefaultCell.tsx)にファイルを分割化
// Issue https://gitlab.ateam.life/ending/ending-taskboad/-/issues/3175
export const WeekCell: FC<Pick<CellProps, 'day'>> = ({ day }) => {
  return (
    <th css={[typography.textS, styles.holidayColor(day.day())]}>
      {day.format('ddd')}
    </th>
  )
}

export const HolidayCell: FC<Omit<CellProps, 'cemeteryId'>> = ({
  day,
  showMonth
}) => {
  return (
    <div css={styles.date}>
      <span
        css={styles.holidayColor(
          JapaneseHolidays.isHoliday(day.toDate()) ? SUNDAY : day.day()
        )}
      >
        {showMonth && <small>{day.format('M')}/</small>}
        {day.format('D')}
      </span>
      <span css={styles.notRequest}>
        <ClearIcon />
      </span>
    </div>
  )
}

export const TelCell: FC<Omit<CellProps, 'cemeteryId'>> = ({
  day,
  showMonth
}) => {
  return (
    <a
      href={`tel:${lifedotCallCenterNumber}`}
      css={[
        styles.date,
        styles.holidayColor(
          JapaneseHolidays.isHoliday(day.toDate()) ? SUNDAY : day.day()
        )
      ]}
      rel="nofollow"
    >
      <span css={styles.dateNum}>
        {showMonth && <small>{day.format('M')}/</small>}
        {day.format('D')}
      </span>
      <span css={[typography.textS, styles.status]}>TEL</span>
    </a>
  )
}

export const DefaultCell: FC<CellProps> = ({ day, cemeteryId, showMonth }) => {
  return (
    <Link
      href={router.requestsNew({
        id: cemeteryId,
        tour: true,
        reservingDate: day.format('YYYY-MM-DD')
      })}
      passHref
      prefetch={false}
    >
      <a
        css={[
          styles.date,
          styles.holidayColor(
            JapaneseHolidays.isHoliday(day.toDate()) ? SUNDAY : day.day()
          )
        ]}
        rel="nofollow"
      >
        <span css={styles.dateNum}>
          {showMonth && <small>{day.format('M')}/</small>}
          {day.format('D')}
        </span>
        <span css={styles.status}>
          <RadioButtonUncheckedIcon />
        </span>
      </a>
    </Link>
  )
}
