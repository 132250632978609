/**
 * @generated SignedSource<<78aa73a8533eb949253c7f629e6890de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type AccessText_cemetery$data = {
  readonly name: string
  readonly nearest_station: {
    readonly name: string | null
    readonly distance: number | null
    readonly stationId: number | null
  }
  readonly prefecture: {
    readonly name: string
    readonly roma: string
  }
  readonly city: {
    readonly name: string
    readonly cityId: number
  }
  readonly accesses: {
    readonly car: string
    readonly other: string
  }
  readonly ' $fragmentType': 'AccessText_cemetery'
}
export type AccessText_cemetery$key = {
  readonly ' $data'?: AccessText_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'AccessText_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'AccessText_cemetery',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryNearestStation',
        kind: 'LinkedField',
        name: 'nearest_station',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'distance',
            storageKey: null
          },
          {
            alias: 'stationId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'roma',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryCity',
        kind: 'LinkedField',
        name: 'city',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: 'cityId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryAccesses',
        kind: 'LinkedField',
        name: 'accesses',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'car',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'other',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = '468ba3b88ff25a27ecb75d9926e81c46'

export default node
