import { ImageCarousel_cemetery$data } from './__generated__/ImageCarousel_cemetery.graphql'

export const makeItems = ({
  images
}: {
  images: ImageCarousel_cemetery$data['images']
}): {
  path: string
  alt: string | null
}[] => {
  return images
    .map(({ alt, path, active }) => {
      if (!path || !active) return null
      return { alt, path }
    })
    .filter((image): image is Exclude<typeof image, null> => image !== null)
}
