import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { Rating } from '@lifedot/atoms/Rating'
import SmsIcon from '@material-ui/icons/Sms'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { Overview_cemetery$data } from './__generated__/Overview_cemetery.graphql'

const styles = {
  review: css({
    display: 'flex',
    gap: 12
  }),
  icon: css({
    verticalAlign: 'bottom',
    color: palette.gray6
  }),
  link: css({
    color: palette.main03
  })
}

interface ReviewProps {
  cemeteryId: number
  summary: Overview_cemetery$data['review_summary']
}

export const Review: FC<ReviewProps> = ({ cemeteryId, summary }) => {
  if (!summary.total_score) return null

  return (
    <div css={styles.review}>
      <Rating point={summary.total_score} />
      <span css={typography.textS}>
        <SmsIcon fontSize="small" css={styles.icon} />
        口コミ
        <Link
          href={router.review(cemeteryId, summary.count)}
          passHref
          prefetch={false}
        >
          <a css={styles.link}>{summary.count}件</a>
        </Link>
      </span>
    </div>
  )
}
