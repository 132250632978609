import { FC, Fragment } from 'react'
import { css } from '@emotion/react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { Appeal_appeals$key } from './__generated__/Appeal_appeals.graphql'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Accordion } from '@lifedot/atoms/Accordion'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  list: css({
    backgroundColor: palette.background,
    padding: '4px 12px'
  }),
  border: css({
    padding: '8px 0',
    ':not(:first-of-type)': {
      borderTop: `1px solid ${palette.gray2}`
    }
  }),
  borderNone: css({
    padding: '4px 0'
  }),
  expandIcon: css({
    marginTop: 4,
    [mq('sp')]: {
      marginTop: 2
    },
    '[open] &': {
      transform: 'rotate(180deg)'
    }
  }),
  headlineText: css({
    flex: '1 1'
  }),
  headline: css({
    display: 'flex',
    fontWeight: 'bold'
  }),
  icon: css({
    color: palette.main02,
    marginRight: 4,
    marginTop: 6,
    [mq('sp')]: {
      marginTop: 4
    }
  }),
  text: css({
    margin: '4px 0 0 24px'
  })
}

interface AppealProps {
  cemetery: Appeal_appeals$key
}

export const Appeal: FC<AppealProps> = ({ cemetery }) => {
  const { descriptions } = useFragment(
    graphql`
      fragment Appeal_appeals on Cemetery {
        descriptions {
          body
          subject
        }
      }
    `,
    cemetery
  )

  return (
    <ul css={styles.list}>
      {descriptions.map(({ body, subject }) => (
        <Fragment key={subject}>
          {body ? (
            <li css={styles.border}>
              <Accordion
                title={
                  <p css={[typography.textXL, styles.headline]}>
                    <CheckCircleIcon css={styles.icon} fontSize="small" />
                    <span css={styles.headlineText}>{subject}</span>
                    <ExpandMoreIcon css={styles.expandIcon} />
                  </p>
                }
                isOpen
              >
                <p css={[typography.textM, styles.text]}>{body}</p>
              </Accordion>
            </li>
          ) : (
            <li css={styles.borderNone}>
              <p css={[typography.textXL, styles.headline]}>
                <CheckCircleIcon css={styles.icon} fontSize="small" />
                {subject}
              </p>
            </li>
          )}
        </Fragment>
      ))}
    </ul>
  )
}
