/**
 * @generated SignedSource<<ba3840e1a18e86e5a62a2f8e2e68cf09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Reviews_reviews$data = {
  readonly name: string
  readonly cemeteryId: number
  readonly review_summary: {
    readonly count: number
  }
  readonly ' $fragmentSpreads': FragmentRefs<
    'ReviewOverview_summary' | 'Voices_reviews' | 'CallToAction_cemetery'
  >
  readonly ' $fragmentType': 'Reviews_reviews'
}
export type Reviews_reviews$key = {
  readonly ' $data'?: Reviews_reviews$data
  readonly ' $fragmentSpreads': FragmentRefs<'Reviews_reviews'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Reviews_reviews',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'ReviewOverview_summary'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'Voices_reviews'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'CallToAction_cemetery'
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    {
      alias: 'cemeteryId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryReviewSummary',
      kind: 'LinkedField',
      name: 'review_summary',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'count',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '0181910d2436ea7b8152c685161381c9'

export default node
