import { FC } from 'react'
import { NextSeo } from 'next-seo'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { makeItems } from './dependencies'
import { makeMetaImage } from '../dependencies'
import { urlify, router } from '@lifedot/router'
import { DetailSeoHead_cemetery$key } from './__generated__/DetailSeoHead_cemetery.graphql'

interface DetailSeoHeadProps {
  cemetery: DetailSeoHead_cemetery$key
}

export const DetailSeoHead: FC<DetailSeoHeadProps> = ({ cemetery }) => {
  const data = useFragment(query, cemetery)
  const { title, description, keywords } = makeItems(data)
  const image = makeMetaImage(data)
  const url = urlify(router.cemetery(data.cemeteryId))

  return (
    <NextSeo
      title={title}
      description={description}
      additionalMetaTags={[
        { name: 'keywords', content: keywords.join(',') },
        { name: 'thumbnail', content: image.path }
      ]}
      canonical={url}
      openGraph={{
        url,
        description,
        title,
        images: [{ url: image.path }]
      }}
    />
  )
}

const query = graphql`
  fragment DetailSeoHead_cemetery on Cemetery {
    cemeteryId: id
    name
    prefecture {
      name
    }
    city {
      name
    }
    nearest_station {
      name
    }
    choices {
      attribute_code
      code
      name
    }
    price_and_specs {
      priceAndSpecId: id
    }
    main_image {
      path
    }
  }
`
