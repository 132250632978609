import { FC, ReactNode } from 'react'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import Image from 'next/image'
import { mq } from '@lifedot/styles/mediaQuery'

const stepStyles = {
  root: css({
    border: `1px solid ${palette.gray2}`,
    width: '25%',
    borderRadius: 4,
    padding: 12,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [mq('sp')]: {
      width: '100%'
    },
    ':not(:last-of-type)': {
      marginRight: 12,
      [mq('sp')]: {
        margin: '0 0 12px 0'
      },
      '::after': {
        content: '""',
        position: 'absolute',
        [mq('pc')]: {
          borderLeft: `12px solid ${palette.gray2}`,
          borderTop: '12px solid transparent',
          borderBottom: '12px solid transparent',
          right: -12,
          top: '50%',
          transform: 'translateY(-50%)'
        },
        [mq('sp')]: {
          borderTop: `8px solid ${palette.gray2}`,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent',
          bottom: 2,
          right: '50%',
          transform: 'translateX(50%)'
        }
      }
    }
  }),
  step: css({
    fontWeight: 'bold',
    backgroundColor: palette.white,
    color: palette.gray6,
    padding: '4px 12px',
    position: 'absolute',
    top: -16
  }),
  item: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [mq('sp')]: {
      flexDirection: 'row'
    }
  }),
  description: css({
    marginTop: 8,
    [mq('sp')]: {
      margin: '0 0 0 8px',
      width: 'calc(70% - 8px)',
      flexShrink: 0
    }
  }),
  title: css({
    textAlign: 'center',
    fontWeight: 'bold',
    [mq('sp')]: {
      textAlign: 'left'
    }
  }),
  text: css({
    [mq('pc')]: {
      marginTop: 8
    }
  })
}

interface StepProps {
  children?: ReactNode
  step: number
  image: { path: string; alt: string }
  title: string
  supplement?: string
}

export const Step: FC<StepProps> = ({
  step,
  image,
  title,
  supplement,
  children
}) => {
  return (
    <li css={stepStyles.root}>
      <p css={stepStyles.step}>STEP{step}</p>
      <div css={stepStyles.item}>
        <Image src={image.path} width={138} height={78} alt={image.alt} />
        <div css={stepStyles.description}>
          <p css={[typography.textL, stepStyles.title]}>{title}</p>
          {supplement && (
            <p css={[typography.textS, stepStyles.text]}>{supplement}</p>
          )}
          {children && <div css={stepStyles.text}>{children}</div>}
        </div>
      </div>
    </li>
  )
}

const stepsStyles = {
  wrap: css({
    marginTop: 12
  }),
  list: css({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    [mq('sp')]: {
      flexDirection: 'column'
    }
  }),
  post: css({
    color: palette.gray6,
    marginTop: 12
  })
}

interface StepsProps {
  children: ReactNode
  head: ReactNode
  caption?: ReactNode
}

export const Steps: FC<StepsProps> = ({ head, caption, children }) => {
  return (
    <div css={stepsStyles.wrap}>
      {head}
      <ol css={stepsStyles.list}>{children}</ol>
      {caption && <p css={[typography.textS, stepsStyles.post]}>{caption}</p>}
    </div>
  )
}
