import { useMemo, FC } from 'react'
import { css } from '@emotion/react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { palette } from '@lifedot/styles/palette'
import { ShortVoice } from './ShortVoice'
import { ShortVoices_cemetery$key } from './__generated__/ShortVoices_cemetery.graphql'
import { orderReviews } from '../Voices/dependencies'

const styles = {
  root: css({
    border: `1px solid ${palette.gray2}`,
    padding: 20
  }),
  voice: css({
    ':not(:first-of-type)': {
      marginTop: 16,
      paddingTop: 16,
      borderTop: `1px solid ${palette.gray2}`
    }
  })
}

type ShortVoicesProps = {
  cemetery: ShortVoices_cemetery$key
}

export const ShortVoices: FC<ShortVoicesProps> = ({ cemetery }) => {
  const { reviews } = useFragment(fragment, cemetery)
  const orderedReviews = useMemo(
    () => orderReviews(reviews).slice(0, 5),
    [reviews]
  )

  return (
    <div css={styles.root}>
      {orderedReviews.map((review) => {
        const answer = review.answers.find(
          ({ code }) => code === 'traffic'
        )?.text
        if (!answer) return null
        return (
          <div css={styles.voice} key={answer}>
            <ShortVoice
              age={review.age}
              gender={review.gender}
              answer={answer}
            />
          </div>
        )
      })}
    </div>
  )
}

const fragment = graphql`
  fragment ShortVoices_cemetery on Cemetery {
    reviews {
      age
      answers {
        code
        label
        score
        text
      }
      average_score
      created_at
      gender
    }
  }
`
