/**
 * @generated SignedSource<<8dba708360c947d1bf2a2d144e1fc5fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type RelatedPost_posts$data = {
  readonly items: ReadonlyArray<{
    readonly h1: string
    readonly slug: string
  }>
  readonly ' $fragmentType': 'RelatedPost_posts'
}
export type RelatedPost_posts$key = {
  readonly ' $data'?: RelatedPost_posts$data
  readonly ' $fragmentSpreads': FragmentRefs<'RelatedPost_posts'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'RelatedPost_posts',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Post',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'h1',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'slug',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'PostResult',
  abstractKey: null
}

;(node as any).hash = '5cecf8107c99d69feb23afff0ab134fd'

export default node
