/**
 * @generated SignedSource<<dacde415ea7aaebafd8d431d39cb0b90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type RelatedCemeteries_cemetery$data = {
  readonly related_cemeteries: ReadonlyArray<{
    readonly cemeteryId: number
    readonly name: string
  }>
  readonly name: string
  readonly ' $fragmentType': 'RelatedCemeteries_cemetery'
}
export type RelatedCemeteries_cemetery$key = {
  readonly ' $data'?: RelatedCemeteries_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'RelatedCemeteries_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'RelatedCemeteries_cemetery',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'CemeterySimple',
        kind: 'LinkedField',
        name: 'related_cemeteries',
        plural: true,
        selections: [
          {
            alias: 'cemeteryId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      },
      v0 /*: any*/
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = 'f37df2e155bf69ec86a7b80d97ba29e4'

export default node
