/**
 * @generated SignedSource<<f5615a2ea83e23dea0d22d557731c4f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type AccessMap_cemetery$data = {
  readonly name: string
  readonly address: string | null
  readonly nearest_station: {
    readonly name: string | null
    readonly distance: number | null
  }
  readonly ' $fragmentSpreads': FragmentRefs<
    'CallToAction_cemetery' | 'EmbeddedMap_cemetery' | 'Methods_cemetery'
  >
  readonly ' $fragmentType': 'AccessMap_cemetery'
}
export type AccessMap_cemetery$key = {
  readonly ' $data'?: AccessMap_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'AccessMap_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'AccessMap_cemetery',
    selections: [
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'CallToAction_cemetery'
      },
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'EmbeddedMap_cemetery'
      },
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'Methods_cemetery'
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'address',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryNearestStation',
        kind: 'LinkedField',
        name: 'nearest_station',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'distance',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = 'e8e8967467b861b9690052c53e253e61'

export default node
