import { FC } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { Applications_applications$key } from './__generated__/Applications_applications.graphql'
import { PostContent } from '@lifedot/components/PostContent'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'

const styles = {
  section: css({
    backgroundColor: palette.gray1,
    borderRadius: 4,
    padding: 20
  }),
  source: css({
    wordBreak: 'break-word'
  })
}

interface ApplicationsProps {
  applications: Applications_applications$key
}

export const Applications: FC<ApplicationsProps> = ({ applications }) => {
  const {
    name,
    applications: { enabled, ...data }
  } = useFragment(fragment, applications)
  if (!enabled) return null

  return (
    <section css={styles.section}>
      <AddMarginWrapper spacing={4}>
        <h3 css={typography.textXL}>{name}の公募案内</h3>
        {(data.accept_start_date || data.accept_stop_date) && (
          <>
            <h4 css={typography.textL}>公募時期</h4>
            <div css={typography.textM}>
              {data.accept_start_date}~{data.accept_stop_date}
            </div>
          </>
        )}
        {data.accept_sub_info && (
          <>
            <h4 css={typography.textL}>公募の予定</h4>
            <div css={typography.textM}>
              <PostContent contentHtml={data.accept_sub_info} />
            </div>
          </>
        )}

        {data.requirement && (
          <>
            <h4 css={typography.textL}>応募資格</h4>
            <div css={typography.textM}>
              <PostContent contentHtml={data.requirement} />
            </div>
          </>
        )}

        {data.price && (
          <>
            <h4 css={typography.textL}>応募区画・料金</h4>
            <div css={typography.textM}>
              <PostContent contentHtml={data.price} />
            </div>
          </>
        )}

        {data.competitive_ratio && (
          <>
            <h4 css={typography.textL}>競争倍率</h4>
            <div css={typography.textM}>
              <PostContent contentHtml={data.competitive_ratio} />
            </div>
          </>
        )}

        {data.subsidies && (
          <>
            <h4 css={typography.textL}>補助制度</h4>
            <div css={typography.textM}>
              <PostContent contentHtml={data.subsidies} />
            </div>
          </>
        )}

        {data.flow && (
          <>
            <h4 css={typography.textL}>申し込みの流れ</h4>
            <div css={typography.textM}>
              <PostContent contentHtml={data.flow} />
            </div>
          </>
        )}

        {data.source && (
          <>
            <h4 css={typography.textL}>出典元</h4>
            <div css={[typography.textM, styles.source]}>{data.source}</div>
          </>
        )}
      </AddMarginWrapper>
    </section>
  )
}

const fragment = graphql`
  fragment Applications_applications on Cemetery {
    name
    applications {
      accept_start_date
      accept_stop_date
      accept_sub_info
      competitive_ratio
      enabled
      flow
      price
      requirement
      source
      subsidies
    }
  }
`
