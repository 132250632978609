/**
 * @generated SignedSource<<d61a772d3f7dcd33bf510b787b2ea5e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Appearance_cemetery$data = {
  readonly requestable: boolean
  readonly ' $fragmentSpreads': FragmentRefs<
    | 'Appeal_appeals'
    | 'ImageCarousel_cemetery'
    | 'CallToAction_cemetery'
    | 'Spec_spec'
    | 'AccessText_cemetery'
  >
  readonly ' $fragmentType': 'Appearance_cemetery'
}
export type Appearance_cemetery$key = {
  readonly ' $data'?: Appearance_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'Appearance_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Appearance_cemetery',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'Appeal_appeals'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'ImageCarousel_cemetery'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'CallToAction_cemetery'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'Spec_spec'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'AccessText_cemetery'
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'requestable',
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '0d2cdaac0ec5cfd2c53014117426ed6f'

export default node
