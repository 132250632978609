/**
 * @generated SignedSource<<9fdc450352c535fae2f1e9b59e7f0eab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type TourSchedule_cemetery$data = {
  readonly cemeteryId: number
  readonly requestable: boolean
  readonly ' $fragmentType': 'TourSchedule_cemetery'
}
export type TourSchedule_cemetery$key = {
  readonly ' $data'?: TourSchedule_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'TourSchedule_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'TourSchedule_cemetery',
  selections: [
    {
      alias: 'cemeteryId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'requestable',
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '4291749f63b6b08df0a2f2a175046db8'

export default node
