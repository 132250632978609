/**
 * @generated SignedSource<<ec64b6ab1efad9e44fd659d437fd0679>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Methods_cemetery$data = {
  readonly accesses: {
    readonly car: string
    readonly taxi: string
    readonly shuttle_bus: string
    readonly other: string
  }
  readonly stations: ReadonlyArray<{
    readonly distance: number
    readonly name: string
    readonly railway_line_name: string
  }>
  readonly flags: {
    readonly parking: boolean
  }
  readonly reviews: ReadonlyArray<{
    readonly answers: ReadonlyArray<{
      readonly code: string
      readonly text: string | null
    }>
  }>
  readonly ' $fragmentSpreads': FragmentRefs<'ShortVoices_cemetery'>
  readonly ' $fragmentType': 'Methods_cemetery'
}
export type Methods_cemetery$key = {
  readonly ' $data'?: Methods_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'Methods_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Methods_cemetery',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'ShortVoices_cemetery'
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryAccesses',
      kind: 'LinkedField',
      name: 'accesses',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'car',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'taxi',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'shuttle_bus',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'other',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryStation',
      kind: 'LinkedField',
      name: 'stations',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'distance',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'railway_line_name',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryFlags',
      kind: 'LinkedField',
      name: 'flags',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'parking',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryReview',
      kind: 'LinkedField',
      name: 'reviews',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'CemeteryReviewAnswer',
          kind: 'LinkedField',
          name: 'answers',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'code',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'text',
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = 'dae68891ae63a365d7ff72d956248875'

export default node
