import { useMemo } from 'react'
import { Methods_cemetery$data } from './__generated__/Methods_cemetery.graphql'

export const useSortedStations = (
  stations: Methods_cemetery$data['stations']
) => {
  return useMemo(() => {
    const stationsGroupByName = stations.reduce<
      Record<string, { railwayLineName: string[]; distance: number }>
    >((res, { name, railway_line_name, distance }) => {
      const station = res[name]
      return {
        ...res,
        [name]: {
          railwayLineName: station
            ? [...station.railwayLineName, railway_line_name]
            : [railway_line_name],
          distance
        }
      }
    }, {})

    return Object.entries(stationsGroupByName)
      .map(([name, { distance, railwayLineName }]) => ({
        name,
        distance,
        railwayLineName: railwayLineName.join('・')
      }))
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 5)
  }, [stations])
}

export const hasTrafficReview = (reviews: Methods_cemetery$data['reviews']) =>
  reviews.some(({ answers }) =>
    answers.some(({ code, text }) => code === 'traffic' && text)
  )
