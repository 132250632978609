import { FC } from 'react'
import { FaqAccordion } from '@lifedot/components/FaqAccordion'
import { useFaqs } from './use-faqs'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { FrequentlyAskedQuestions_cemetery$key } from './__generated__/FrequentlyAskedQuestions_cemetery.graphql'
import { markup } from '@lifedot/utils'
import { Section } from '../Section/Section'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  content: css({
    marginTop: 8,
    h3: {
      padding: 8
    }
  })
}

interface FrequentlyAskedQuestionsProps {
  cemetery: FrequentlyAskedQuestions_cemetery$key
}

export const FrequentlyAskedQuestions: FC<FrequentlyAskedQuestionsProps> = ({
  cemetery
}) => {
  const data = useFragment(fragment, cemetery)
  const faqs = useFaqs({ faqs: data })
  return (
    <Section itemProp="text" itemScope itemType="https://schema.org/FAQPage">
      <h2 css={typography.headingXS}>{data.name}のよくある質問</h2>
      <div css={styles.content}>
        {faqs.map(({ question, answer, category }, index) => (
          <EventTracker
            clone
            key={index}
            label="faq_よくある質問全て"
            action="click"
            elementCategory={elementCategories.faq}
            elementLabel={category}
          >
            <FaqAccordion title={question}>
              <span dangerouslySetInnerHTML={markup(answer)} />
            </FaqAccordion>
          </EventTracker>
        ))}
      </div>
    </Section>
  )
}

const fragment = graphql`
  fragment FrequentlyAskedQuestions_cemetery on Cemetery {
    ...useFaqs_faqs
    name
  }
`
