/**
 * @generated SignedSource<<b522949d7056d836e6514796e71bc486>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShortVoices_cemetery$data = {
  readonly reviews: ReadonlyArray<{
    readonly age: number
    readonly answers: ReadonlyArray<{
      readonly code: string
      readonly label: string
      readonly score: number
      readonly text: string | null
    }>
    readonly average_score: number
    readonly created_at: string
    readonly gender: string
  }>
  readonly ' $fragmentType': 'ShortVoices_cemetery'
}
export type ShortVoices_cemetery$key = {
  readonly ' $data'?: ShortVoices_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShortVoices_cemetery'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ShortVoices_cemetery',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryReview',
      kind: 'LinkedField',
      name: 'reviews',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'age',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: 'CemeteryReviewAnswer',
          kind: 'LinkedField',
          name: 'answers',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'code',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'label',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'score',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'text',
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'average_score',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'created_at',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'gender',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '7f69d2be3083e974612c3a83b79690b1'

export default node
