/**
 * @generated SignedSource<<f242e42cb145735997ea1529669e2a91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Overview_cemetery$data = {
  readonly cemeteryId: number
  readonly name: string
  readonly kana: string | null
  readonly prefecture: {
    readonly roma: string
  }
  readonly review_summary: {
    readonly count: number
    readonly total_score: number | null
  }
  readonly nearest_station: {
    readonly name: string | null
    readonly distance: number | null
  }
  readonly address: string | null
  readonly requestable: boolean
  readonly cemetery_types: ReadonlyArray<{
    readonly code: string
    readonly name: string
  }>
  readonly section_types: ReadonlyArray<{
    readonly name: string
    readonly code: string
  }>
  readonly sects: ReadonlyArray<{
    readonly name: string
  }>
  readonly ' $fragmentSpreads': FragmentRefs<'CemeteryPriceTable_cemetery'>
  readonly ' $fragmentType': 'Overview_cemetery'
}
export type Overview_cemetery$key = {
  readonly ' $data'?: Overview_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'Overview_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'code',
      storageKey: null
    }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'Overview_cemetery',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'kana',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'roma',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryReviewSummary',
        kind: 'LinkedField',
        name: 'review_summary',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'count',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'total_score',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryNearestStation',
        kind: 'LinkedField',
        name: 'nearest_station',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'distance',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'address',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'requestable',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'cemetery_types',
        plural: true,
        selections: [v1 /*: any*/, v0 /*: any*/],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'section_types',
        plural: true,
        selections: [v0 /*: any*/, v1 /*: any*/],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'sects',
        plural: true,
        selections: [v0 /*: any*/],
        storageKey: null
      },
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'CemeteryPriceTable_cemetery'
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = '20cf2139ef39c1c053d4d1de556fcabc'

export default node
