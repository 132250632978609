/**
 * @generated SignedSource<<1984772f4122cd381c1aa1ff6bab2d00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Abouts_abouts$data = {
  readonly name: string
  readonly ' $fragmentSpreads': FragmentRefs<
    | 'Details_details'
    | 'Applications_applications'
    | 'DirectorComment_comment'
    | 'RelatedCemeteries_cemetery'
    | 'CallToAction_cemetery'
  >
  readonly ' $fragmentType': 'Abouts_abouts'
}
export type Abouts_abouts$key = {
  readonly ' $data'?: Abouts_abouts$data
  readonly ' $fragmentSpreads': FragmentRefs<'Abouts_abouts'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Abouts_abouts',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'Details_details'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'Applications_applications'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'DirectorComment_comment'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'RelatedCemeteries_cemetery'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'CallToAction_cemetery'
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '578903b292dabd5abca4bee0ba2a6ffa'

export default node
