/**
 * @generated SignedSource<<e7d25c3400cbe2b25955935e15d52921>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type DetailStructuredData_cemetery$data = {
  readonly cemeteryId: number
  readonly name: string
  readonly created_at: string
  readonly updated_at: string
  readonly address: string | null
  readonly details: {
    readonly postal_code: string
  }
  readonly choices: ReadonlyArray<{
    readonly name: string
    readonly code: string
  }>
  readonly review_summary: {
    readonly total_score: number | null
    readonly count: number
  }
  readonly requestable: boolean
  readonly location: {
    readonly lat: number
    readonly lon: number
  }
  readonly main_image: {
    readonly path: string | null
  } | null
  readonly section_types: ReadonlyArray<{
    readonly code: string
    readonly name: string
  }>
  readonly prefecture: {
    readonly name: string
  }
  readonly city: {
    readonly name: string
  }
  readonly nearest_station: {
    readonly name: string | null
  }
  readonly price_and_specs: ReadonlyArray<{
    readonly price1: number | null
  }>
  readonly ' $fragmentType': 'DetailStructuredData_cemetery'
}
export type DetailStructuredData_cemetery$key = {
  readonly ' $data'?: DetailStructuredData_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'DetailStructuredData_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'code',
      storageKey: null
    },
    v2 = [v0 /*: any*/]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'DetailStructuredData_cemetery',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'created_at',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'updated_at',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'address',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryDetails',
        kind: 'LinkedField',
        name: 'details',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'postal_code',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'choices',
        plural: true,
        selections: [v0 /*: any*/, v1 /*: any*/],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryReviewSummary',
        kind: 'LinkedField',
        name: 'review_summary',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'total_score',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'count',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'requestable',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'Location',
        kind: 'LinkedField',
        name: 'location',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lat',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lon',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryImage',
        kind: 'LinkedField',
        name: 'main_image',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'path',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'section_types',
        plural: true,
        selections: [v1 /*: any*/, v0 /*: any*/],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: v2 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryCity',
        kind: 'LinkedField',
        name: 'city',
        plural: false,
        selections: v2 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryNearestStation',
        kind: 'LinkedField',
        name: 'nearest_station',
        plural: false,
        selections: v2 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPriceAndSpec',
        kind: 'LinkedField',
        name: 'price_and_specs',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'price1',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = '8a50e5f16ddb0d9848a309747e32dde3'

export default node
