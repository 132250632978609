import { FC } from 'react'
import { ImageCarousel } from './ImageCarousel/ImageCarousel'
import { Appeal } from './Appeal/Appeal'
import { Section } from '@/components/Section/Section'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { Appearance_cemetery$key } from './__generated__/Appearance_cemetery.graphql'
import { CallToAction } from '@/components/CallToAction'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { Spec } from '@/components/Appearance/Spec'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { AccessText } from '@/components/Overview/AccessText'
import { NotRequestableNote } from '@/components/Overview/NotRequestableNote'

const styles = {
  carousel: css({
    backgroundColor: palette.white,
    padding: '0 40px',
    [mq('sp')]: {
      padding: 0
    }
  }),
  cta: css({
    display: 'none',
    [mq('sp')]: {
      display: 'grid',
      gap: 8,
      padding: 16
    }
  }),
  note: css({
    padding: 8,
    backgroundColor: palette.gray2,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold'
  })
}

interface AppearanceProps {
  cemetery: Appearance_cemetery$key
}

export const Appearance: FC<AppearanceProps> = ({ cemetery }) => {
  const data = useFragment(fragment, cemetery)

  return (
    <>
      <div css={styles.carousel}>
        <ImageCarousel cemetery={data} />
        <div css={styles.cta}>
          {!data.requestable && <NotRequestableNote />}
          <CallToAction
            cemetery={data}
            eventTrack
            eventLabelIndex="overview_"
          />
        </div>
      </div>
      <Section>
        <AddMarginWrapper spacing={2}>
          <Appeal cemetery={data} />
          <AddMarginWrapper spacing={2}>
            <Spec spec={data} />
            <AccessText cemetery={data} />
          </AddMarginWrapper>
        </AddMarginWrapper>
      </Section>
    </>
  )
}

const fragment = graphql`
  fragment Appearance_cemetery on Cemetery {
    ...Appeal_appeals
    ...ImageCarousel_cemetery
    ...CallToAction_cemetery
    ...Spec_spec
    ...AccessText_cemetery
    requestable
  }
`
