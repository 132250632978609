/**
 * @generated SignedSource<<76fb13298e22a31cf4b5c3309d562918>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type useFaqs_cemeteryHas$data = {
  readonly faqs: ReadonlyArray<{
    readonly question: string
    readonly answer: string
  }>
  readonly ' $fragmentType': 'useFaqs_cemeteryHas'
}
export type useFaqs_cemeteryHas$key = {
  readonly ' $data'?: useFaqs_cemeteryHas$data
  readonly ' $fragmentSpreads': FragmentRefs<'useFaqs_cemeteryHas'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'useFaqs_cemeteryHas',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryFAQ',
      kind: 'LinkedField',
      name: 'faqs',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'question',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'answer',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '4045f3e239cdb4a70c4115e3a0eeae75'

export default node
