import { FC } from 'react'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import Image from 'next/image'
import Link from 'next/link'
import { Button } from '@lifedot/atoms/Button'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { router } from '@lifedot/router'

const styles = {
  cta: css({
    backgroundColor: palette.background,
    padding: 20
  }),
  textMain: css({
    color: palette.main03
  })
}

interface HakajimaiProps {
  name: string
}

export const Hakajimai: FC<HakajimaiProps> = ({ name }) => {
  return (
    <AddMarginWrapper spacing={2}>
      <h3 css={typography.textXL}>墓じまいによる改葬受入が可能</h3>
      <p>
        {name}
        に空き区画があれば、ご先祖様のご遺骨を埋蔵し直す場所として、お墓をご利用いただけます。
        <br />
        墓じまいをした後の改葬先や、お墓の引っ越し先選びは、ライフドットにご相談ください。
      </p>
      <div css={styles.cta}>
        <HakajimaiContents />
        <HakajimaiLinks />
      </div>
    </AddMarginWrapper>
  )
}

const contentsStyles = {
  grid: css({
    display: 'grid',
    gridTemplateColumns: '108px 1fr',
    alignItems: 'center',
    columnGap: 12,
    [mq('sp')]: {
      gridTemplateColumns: '80px 1fr'
    }
  }),
  img: css({
    gridColumn: '1 / 2',
    gridRow: '1 / 3',
    [mq('sp')]: {
      gridRow: '1 / 2'
    }
  }),
  subtitle: css({
    gridColumn: '2 / 3',
    gridRow: '1 / 2'
  }),
  contents: css({
    gridColumn: '2 / 3',
    gridRow: '2 / 3',
    [mq('sp')]: {
      gridColumn: '1 / 3'
    }
  })
}

const HakajimaiContents: FC = () => {
  return (
    <div css={contentsStyles.grid}>
      <div css={contentsStyles.img}>
        <Image
          src="https://s3.lifedot.jp/uploads/ending/path/54596/img_hakajimai_guidebook.png"
          width={141}
          height={120}
          alt="墓じまいガイドブック"
        />
      </div>
      <p css={[typography.headingXS, styles.textMain, contentsStyles.subtitle]}>
        墓じまいガイドブック無料プレゼント
      </p>
      <p css={[typography.textM, contentsStyles.contents]}>
        墓じまいの手順や、実際のお客様の体験談など、墓じまいのすべてがわかる資料をお送りいたします。
      </p>
    </div>
  )
}

const linksStyles = {
  root: css({
    maxWidth: 400,
    margin: '12px auto 0'
  }),
  button: css({
    textDecoration: 'none'
  }),
  textLink: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12
  })
}

const HakajimaiLinks: FC = () => {
  return (
    <div css={linksStyles.root}>
      <Link
        href="/ohaka/hakajimai_requests/new/?guidebook=true"
        passHref
        prefetch={false}
      >
        <a css={linksStyles.button} rel="nofollow">
          <Button
            as="div"
            mainText={
              <span css={typography.textM}>ガイドブックを請求する</span>
            }
          />
        </a>
      </Link>
      <a
        href={router.hakajimai()}
        target="_blank"
        rel="noopener noreferrer nofollow"
        css={[typography.textS, styles.textMain, linksStyles.textLink]}
      >
        ライフドットの墓じまいについて
        <ChevronRightIcon fontSize="small" />
      </a>
    </div>
  )
}
