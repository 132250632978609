import { FC } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { distanceFormatter } from '@lifedot/utils'
import { EmbeddedMapProps } from './EmbeddedMap'
import dynamic from 'next/dynamic'
import { typography } from '@lifedot/styles/typography'
import { CallToAction } from '../CallToAction'
import { Section } from '../Section/Section'
import { useInView } from 'react-intersection-observer'
import { CtaSection } from '@/components/CtaSection/CtaSection'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import TrainIcon from '@material-ui/icons/Train'
import RoomIcon from '@material-ui/icons/Room'
import { Methods } from './Methods'
import { AccessMap_cemetery$key } from './__generated__/AccessMap_cemetery.graphql'
import { AccessMap_access$key } from './__generated__/AccessMap_access.graphql'

const styles = {
  accesses: css({
    display: 'flex',
    [mq('sp')]: {
      flexDirection: 'column'
    }
  }),
  address: css({
    marginRight: 24
  }),
  icon: css({
    color: palette.gray4,
    verticalAlign: 'bottom'
  })
}

interface AccessMapProps {
  cemetery: AccessMap_cemetery$key
  access: AccessMap_access$key
}

export const AccessMap: FC<AccessMapProps> = ({ cemetery, access }) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const data = useFragment(cemeteryFragment, cemetery)
  const { name, address, nearest_station: station } = data
  const accessData = useFragment(accessFragment, access)

  return (
    <>
      <Section id="a-access" title={`${name}の地図・交通アクセス`}>
        <AddMarginWrapper spacing={2}>
          <div css={styles.accesses} ref={ref}>
            <p css={[typography.textM, styles.address]}>
              <RoomIcon css={styles.icon} />
              {address}
            </p>
            {!!station.name && !!station.distance && (
              <p css={typography.textM}>
                <TrainIcon css={styles.icon} />
                {station.name}駅({distanceFormatter(station.distance)})
              </p>
            )}
          </div>
          {inView && <EmbeddedMap cemetery={data} />}
          <Methods cemetery={data} access={accessData} />
        </AddMarginWrapper>
      </Section>
      <CtaSection>
        <CallToAction cemetery={data} eventTrack eventLabelIndex="access_" />
      </CtaSection>
    </>
  )
}

const EmbeddedMap: FC<EmbeddedMapProps> = (props) => {
  const Component = dynamic<EmbeddedMapProps>(() =>
    import('./EmbeddedMap').then((mod) => mod.EmbeddedMap)
  )

  return <Component {...props} />
}

const cemeteryFragment = graphql`
  fragment AccessMap_cemetery on Cemetery {
    ...CallToAction_cemetery
    ...EmbeddedMap_cemetery
    ...Methods_cemetery
    name
    address
    nearest_station {
      name
      distance
    }
  }
`

const accessFragment = graphql`
  fragment AccessMap_access on ServiceReportResult {
    ...Methods_access
  }
`
