import { SectionTypeCode } from '@lifedot/constants/searchChoices'

type PurchaseFlowTypes = Array<
  { type: 'normal'; name: string } | { type: 'other'; name: string }
>

export const makePurchaseFlowTypes = (
  sectionTypes: { code: string; name: string }[]
): PurchaseFlowTypes => {
  const normal = sectionTypes.filter(
    ({ code }) => code === SectionTypeCode.NORMAL
  )
  const others = sectionTypes.filter(
    ({ code }) => code !== SectionTypeCode.NORMAL
  )
  const res: PurchaseFlowTypes = []
  if (normal.length) res.push({ type: 'normal', name: '一般墓' })
  if (others.length)
    res.push({
      type: 'other',
      name: others.map(({ name }) => name).join('・')
    })
  return res
}
