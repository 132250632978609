/**
 * @generated SignedSource<<a8b89d82c1fcf19a437cdd28d2772d2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type BestBuyPrices_cemetery$data = {
  readonly cemeteryId: number
  readonly name: string
  readonly choices: ReadonlyArray<{
    readonly code: string
  }>
  readonly requestable: boolean
  readonly price_and_specs: ReadonlyArray<{
    readonly specsId: number
  }>
  readonly best_price_by_category: {
    readonly normal: {
      readonly priceId: number
      readonly name: string | null
      readonly category_code: string
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly price4_unit: string | null
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly price3_unit: string | null
      readonly simulated_price3: number | null
      readonly lot_area: number | null
      readonly lot_area_spec: string | null
      readonly capacity: number | null
      readonly spec: string | null
      readonly image: {
        readonly alt: string | null
        readonly url: string
      } | null
    } | null
    readonly nokotsu: {
      readonly priceId: number
      readonly name: string | null
      readonly category_code: string
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly price4_unit: string | null
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly price3_unit: string | null
      readonly simulated_price3: number | null
      readonly lot_area: number | null
      readonly lot_area_spec: string | null
      readonly capacity: number | null
      readonly spec: string | null
      readonly image: {
        readonly alt: string | null
        readonly url: string
      } | null
    } | null
    readonly jumoku: {
      readonly priceId: number
      readonly name: string | null
      readonly category_code: string
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly price4_unit: string | null
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly price3_unit: string | null
      readonly simulated_price3: number | null
      readonly lot_area: number | null
      readonly lot_area_spec: string | null
      readonly capacity: number | null
      readonly spec: string | null
      readonly image: {
        readonly alt: string | null
        readonly url: string
      } | null
    } | null
    readonly eitai_kuyo: {
      readonly priceId: number
      readonly name: string | null
      readonly category_code: string
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly price4_unit: string | null
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly price3_unit: string | null
      readonly simulated_price3: number | null
      readonly lot_area: number | null
      readonly lot_area_spec: string | null
      readonly capacity: number | null
      readonly spec: string | null
      readonly image: {
        readonly alt: string | null
        readonly url: string
      } | null
    } | null
    readonly other: {
      readonly priceId: number
      readonly name: string | null
      readonly category_code: string
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly price4_unit: string | null
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly price3_unit: string | null
      readonly simulated_price3: number | null
      readonly lot_area: number | null
      readonly lot_area_spec: string | null
      readonly capacity: number | null
      readonly spec: string | null
      readonly image: {
        readonly alt: string | null
        readonly url: string
      } | null
    } | null
  }
  readonly ' $fragmentSpreads': FragmentRefs<'CallToAction_cemetery'>
  readonly ' $fragmentType': 'BestBuyPrices_cemetery'
}
export type BestBuyPrices_cemetery$key = {
  readonly ' $data'?: BestBuyPrices_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'BestBuyPrices_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v1 = [
      {
        alias: 'priceId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'category_code',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price1',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price1_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price2',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price2_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'calculated_purchase_price',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price4',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price4_name',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price4_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price_option',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price3',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price3_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'simulated_price3',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lot_area',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lot_area_spec',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'capacity',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'spec',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPriceAndSpecImage',
        kind: 'LinkedField',
        name: 'image',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'alt',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'url',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'BestBuyPrices_cemetery',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'choices',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'code',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'requestable',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPriceAndSpec',
        kind: 'LinkedField',
        name: 'price_and_specs',
        plural: true,
        selections: [
          {
            alias: 'specsId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryBestPriceByCategory',
        kind: 'LinkedField',
        name: 'best_price_by_category',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'normal',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'nokotsu',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'jumoku',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'eitai_kuyo',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'other',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'CallToAction_cemetery'
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = 'a1ec1f0498869c0c39184c3d02bed280'

export default node
