import { graphql } from 'relay-runtime'

export const cemeteryPageBuildTargetsQuery = graphql`
  query cemeteryPage_buildTargetsQuery(
    $size: Int!
    $from: Int!
    $sort: CemeterySortInput
  ) {
    cemeteries: listCemeteries(
      filter: {}
      page: { size: $size, from: $from }
      sort: $sort
    ) {
      items {
        cemeteryId: id
      }
      pagination {
        hasNext
      }
    }
  }
`

export const cemeteryPagePrepareQuery = graphql`
  query cemeteryPage_prepareQuery($cemeteryId: Int!) {
    cemetery: getCemetery(id: $cemeteryId) {
      name
      redirect_to
      prefecture {
        roma
      }
      city {
        cityId: id
      }
      review_summary {
        count
      }
      best_price_by_category {
        normal {
          name
        }
        nokotsu {
          name
        }
        eitai_kuyo {
          name
        }
        jumoku {
          name
        }
        other {
          name
        }
      }
      visible
    }
  }
`

export const cemeteryPageQuery = graphql`
  query cemeteryPage_indexQuery(
    $cemeteryId: Int!
    $cityId: Int!
    $prefectureRoma: String!
  ) {
    cemetery: getCemetery(id: $cemeteryId) {
      ...Overview_cemetery
      ...Reviews_reviews
      ...BestBuyPrices_cemetery
      ...Abouts_abouts
      ...DetailSeoHead_cemetery
      ...FrequentlyAskedQuestions_cemetery
      ...Anchors_cemetery
      ...ThirdPartyAdData_cemetery
      ...DetailStructuredData_cemetery
      ...LastUpdated_date
      ...Appearance_cemetery
      ...Breadcrumb_cemetery
      ...PurchaseFlow_cemetery
      ...Links_cemetery
      ...AccessMap_cemetery
      ...TourSchedule_cemetery
      ...SiteDescription_cemetery
      ...StickyTelephoneBanner_cemetery
    }
    access: listServiceReports(
      serviceId: $cemeteryId
      filter: { codes: ["access"] }
    ) {
      ...AccessMap_access
    }
    features: listServiceReports(
      serviceId: $cemeteryId
      filter: { codes: ["feature"] }
    ) {
      ...ServiceReportFeatures_features
    }
    nearbyCities: listNearbyCitiesWithCemeteryCountByCityId(cityId: $cityId) {
      ...LinksNearbyCity_nearbyCities
    }
    conditionWithCounts: listSearchChoicesWithCemeteryCount(
      cemeteryFilter: { cities: [$cityId] }
      limit: 99
    ) {
      ...LinksCondition_conditionWithCounts
    }
    posts: listPosts(filter: { holder_cemeteries: [$cemeteryId] }) {
      ...Abouts_posts
      ...PurchaseFlow_posts
    }
    prefectureCemeteries: listCemeteries(
      filter: { prefectures: [$prefectureRoma] }
      page: { from: 0, size: 11 }
      sort: { field: "ranking", order: "desc" }
    ) {
      ...PrefectureListCemeteries_prefectureCemeteries
    }
    prefectureCityCemeteries: listCemeteries(
      filter: { prefectures: [$prefectureRoma], cities: [$cityId] }
      page: { from: 0, size: 11 }
      sort: { field: "ranking", order: "desc" }
    ) {
      ...PrefCityListCemeteries_prefectureCityCemeteries
    }
  }
`
