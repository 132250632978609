import { BestBuyPrices_cemetery$data } from '@/components/BestBuyPrices/__generated__/BestBuyPrices_cemetery.graphql'
import {
  ConvertedPriceDetails,
  ConvertedPurchasePriceObject,
  isKoei,
  purchasePrice
} from '@/components/Prices/dependencies'
import { sectionTypes } from '@lifedot/constants/searchChoices'
import { ComponentProps } from 'react'
import { SectionTypePriceItem } from '@/components/Prices/SectionTypePriceItem'
export { isKoei, makeTitle } from '@/components/Prices/dependencies'

export const makeItems = (
  data: BestBuyPrices_cemetery$data
): Array<
  ComponentProps<typeof SectionTypePriceItem> & { sectionName: string }
> => {
  if (!data.best_price_by_category) return []
  return types.reduce<
    Array<ComponentProps<typeof SectionTypePriceItem> & { sectionName: string }>
  >((res, { code, name }) => {
    const bestBuy = data.best_price_by_category[code]
    if (!bestBuy) return res
    return [
      ...res,
      {
        ...convert(bestBuy, isKoei(data.choices), data.requestable),
        name: bestBuy.name,
        image: bestBuy.image,
        spec: null,
        labels: [],
        sectionName: name,
        hash: `${code}-detail-${bestBuy.priceId}`
      }
    ]
  }, [])
}

const types = [...sectionTypes, { code: 'other', name: 'その他' }] as {
  code: keyof Exclude<
    BestBuyPrices_cemetery$data['best_price_by_category'],
    null
  >
  name: string
}[]

export const convert = (
  item: Exclude<
    BestBuyPrices_cemetery$data['best_price_by_category']['normal'],
    null
  >,
  isKoei: boolean,
  requestable: boolean
): {
  details: ConvertedPriceDetails
  purchasePrice: ConvertedPurchasePriceObject
} => {
  return {
    purchasePrice: purchasePrice(item),
    details: details(item, isKoei, requestable)
  }
}

const details = (
  priceAndSpec: Exclude<
    BestBuyPrices_cemetery$data['best_price_by_category']['normal'],
    null
  >,
  isKoei: boolean,
  requestable: boolean
): ConvertedPriceDetails => {
  const details = []
  if (priceAndSpec.lot_area || priceAndSpec.lot_area_spec)
    details.push({
      label: '面積/特徴',
      content: `${priceAndSpec.lot_area ? `${priceAndSpec.lot_area}㎡ ` : ''}${
        priceAndSpec.lot_area_spec ?? ''
      }`
    })
  details.push({
    label: '空き状況',
    content: !isKoei && requestable ? '空きあり' : '要確認'
  })

  return details
}
