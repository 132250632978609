import { useEffect, useState, FC } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/ja'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { isNewYearsHoliday } from '@lifedot/utils'
import {
  DefaultCell,
  HolidayCell,
  TelCell,
  WeekCell
} from './TourSceduleTableCell'

dayjs.locale('ja')

const TEL_SUPPORT_DAYS_PERIOD = 1

const styles = {
  calender: css({
    width: '100%',
    'th, td': {
      textAlign: 'center',
      borderColor: palette.gray2,
      borderStyle: 'solid'
    },
    td: {
      borderWidth: '0 1px 1px 1px',
      width: 'calc(100% / 7)'
    },
    th: {
      backgroundColor: palette.background,
      borderWidth: '1px 1px 0 1px'
    }
  })
}

interface TourScheduleTableProps {
  cemeteryId: number
}

export const TourScheduleTable: FC<TourScheduleTableProps> = ({
  cemeteryId
}) => {
  const [days, setDays] = useState<Dayjs[]>([])
  useEffect(() => {
    setDays([...Array(14)].map((_, index) => dayjs().add(index, 'day')))
  }, [])

  return (
    <table css={styles.calender}>
      <thead>
        <tr>
          {days.slice(0, 7).map((day, index) => (
            <WeekCell day={day} key={index} />
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {days.slice(0, 7).map((day, index) => {
            const isTelSupportDay = index < TEL_SUPPORT_DAYS_PERIOD
            const showMonth = index === 0 || day.date() === 1
            return (
              <td key={day.format('YYYY-MM-DD')}>
                {isNewYearsHoliday(day) ? (
                  <HolidayCell day={day} showMonth={showMonth} />
                ) : isTelSupportDay ? (
                  <TelCell day={day} showMonth={showMonth} />
                ) : (
                  <DefaultCell
                    day={day}
                    cemeteryId={cemeteryId}
                    showMonth={showMonth}
                  />
                )}
              </td>
            )
          })}
        </tr>
        <tr>
          {days.slice(7, 14).map((day) => {
            const showMonth = day.date() === 1
            return (
              <td key={day.format('YYYY-MM-DD')}>
                {isNewYearsHoliday(day) ? (
                  <HolidayCell day={day} showMonth={showMonth} />
                ) : (
                  <DefaultCell
                    day={day}
                    cemeteryId={cemeteryId}
                    showMonth={showMonth}
                  />
                )}
              </td>
            )
          })}
        </tr>
      </tbody>
    </table>
  )
}
