/**
 * @generated SignedSource<<61b3fcfcebaa5a3a17ecb2d2919d0c29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Abouts_posts$data = {
  readonly ' $fragmentSpreads': FragmentRefs<'RelatedPost_posts'>
  readonly ' $fragmentType': 'Abouts_posts'
}
export type Abouts_posts$key = {
  readonly ' $data'?: Abouts_posts$data
  readonly ' $fragmentSpreads': FragmentRefs<'Abouts_posts'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Abouts_posts',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'RelatedPost_posts'
    }
  ],
  type: 'PostResult',
  abstractKey: null
}

;(node as any).hash = 'a363eccc3a920ad69bf3668dea3afcc7'

export default node
