import { FC } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'relay-hooks'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { TourScheduleTable } from './TourScheduleTable'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { Section } from '../Section/Section'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { palette } from '@lifedot/styles/palette'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { TourSchedule_cemetery$key } from './__generated__/TourSchedule_cemetery.graphql'

const styles = {
  linkWrap: css({
    textAlign: 'right',
    marginTop: 8
  }),
  link: css({
    display: 'inline-flex',
    alignItems: 'center',
    color: palette.main03
  })
}

interface TourScheduleProps {
  cemetery: TourSchedule_cemetery$key
}

export const TourSchedule: FC<TourScheduleProps> = ({ cemetery }) => {
  const { cemeteryId, requestable } = useFragment(fragment, cemetery)

  if (!requestable) return null
  return (
    <Section title="見学予約スケジュール">
      <AddMarginWrapper spacing={2}>
        <p css={typography.textM}>見学をご希望の日程をお選びください。</p>
        <TourScheduleTable cemeteryId={cemeteryId} />
        <p css={styles.linkWrap}>
          <Link
            href={router.requestsNew({ id: cemeteryId, tour: true })}
            passHref
          >
            <a css={[typography.textM, styles.link]} rel="nofollow">
              他の見学可能な日程で予約する
              <ChevronRightIcon fontSize="small" />
            </a>
          </Link>
        </p>
      </AddMarginWrapper>
    </Section>
  )
}

const fragment = graphql`
  fragment TourSchedule_cemetery on Cemetery {
    cemeteryId: id
    requestable
  }
`
