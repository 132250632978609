import * as React from 'react'
import { css } from '@emotion/react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { Overview_cemetery$key } from './__generated__/Overview_cemetery.graphql'
import { CemeteryPriceTable } from './CemeteryPriceTable'
import { Access } from './Access'
import { Attributes } from './Attributes'
import { Review } from './Review'
import { mq, WindowWidth } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { Divider } from '@lifedot/atoms/Divider'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { StockListButton } from '@/components/CallToAction/StockListButton'
import { elementCategories } from '@lifedot/constants/elementCategories'
import { CallToAction } from './CallToAction'
import { PrefectureRomas } from '@lifedot/constants/prefectures'
import { useIsUrban } from '@lifedot/hooks/use-is-urban'

const styles = {
  header: css({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: 20,
    alignItems: 'start',
    backgroundColor: palette.white,
    padding: '32px 40px',
    [mq('max', WindowWidth.TABLET)]: {
      gridTemplateColumns: '3fr 2fr'
    },
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      gap: 8,
      padding: '12px 8px'
    }
  }),
  rubi: css({
    display: 'block',
    lineHeight: 1.4
  }),
  reviewWrap: css({
    display: 'flex',
    alignItems: 'end',
    [mq('max', WindowWidth.TABLET)]: {
      display: 'block'
    }
  }),
  stock: css({
    width: '100%',
    maxWidth: 168,
    marginLeft: 'auto'
  }),
  pc: css({
    [mq('sp')]: {
      display: 'none'
    }
  })
}

interface OverviewProps {
  cemetery: Overview_cemetery$key
}

export const Overview: React.FC<OverviewProps> = ({ cemetery }) => {
  const data = useFragment(overviewFragmentQuery, cemetery)
  const {
    cemeteryId,
    name,
    kana,
    review_summary,
    nearest_station: nearestStation,
    address,
    requestable,
    section_types: sectionTypes,
    sects: [sect],
    cemetery_types,
    prefecture
  } = data
  const cemeteryType = cemetery_types.find(({ code }) => code !== 'not_koei')
  const isUrban = useIsUrban(prefecture.roma as PrefectureRomas)

  return (
    <header css={styles.header}>
      <AddMarginWrapper spacing={2}>
        <div>
          <span css={[typography.textS, styles.rubi]}>{kana}</span>
          <h1 css={typography.headingXL}>{name}</h1>
          <div css={styles.reviewWrap}>
            <Review cemeteryId={cemeteryId} summary={review_summary} />
            {requestable && (
              <div css={[styles.stock, styles.pc]}>
                <StockListButton
                  data-event-tracking
                  data-event-action="click"
                  data-event-label="overview_検討リスト"
                  data-event-element-category={elementCategories.stockList}
                  data-event-element-label={`click_${cemeteryId}`}
                  cemeteryId={cemeteryId}
                  small
                />
              </div>
            )}
          </div>
        </div>
        <Divider />
        <Access
          cemeteryId={cemeteryId}
          nearestStation={nearestStation}
          address={address}
        />
        <Attributes cemeteryType={cemeteryType} sect={sect} />
        <CemeteryPriceTable cemetery={data} />
      </AddMarginWrapper>
      <div css={styles.pc}>
        <CallToAction
          cemeteryId={cemeteryId}
          requestable={requestable}
          sectionTypes={[...sectionTypes]}
          hideTelButton={!isUrban || !requestable}
        />
      </div>
    </header>
  )
}

const overviewFragmentQuery = graphql`
  fragment Overview_cemetery on Cemetery {
    cemeteryId: id
    name
    kana
    prefecture {
      roma
    }
    review_summary {
      count
      total_score
    }
    nearest_station {
      name
      distance
    }
    address
    requestable
    cemetery_types {
      code
      name
    }
    section_types {
      name
      code
    }
    sects {
      name
    }
    ...CemeteryPriceTable_cemetery
  }
`
