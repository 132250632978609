import { FC } from 'react'
import {
  ageDecadeFormatter,
  genderI18n,
  getReviewImagePath
} from '@lifedot/utils'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import Image from 'next/image'

const styles = {
  table: css({
    th: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '36px 1fr',
      gap: 8,
      fontWeight: 'bold'
    },
    td: {
      display: 'block',
      marginTop: 12
    }
  })
}

interface ShortVoiceProps {
  age: number
  gender: string
  answer: string
}

export const ShortVoice: FC<ShortVoiceProps> = ({ age, gender, answer }) => {
  return (
    <table css={styles.table}>
      <thead>
        <tr>
          <th>
            <Image
              src={getReviewImagePath({ age, gender })}
              width={36}
              height={36}
              alt=""
            />
            <p css={typography.textS}>
              {ageDecadeFormatter(age)}・{genderI18n(gender)}
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td css={typography.textM}>{answer}</td>
        </tr>
      </tbody>
    </table>
  )
}
