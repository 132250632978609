/**
 * @generated SignedSource<<5e84763e7b98327d6032f69159b516be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ServiceReportFeatures_features$data = {
  readonly items: ReadonlyArray<{
    readonly content_text: string
    readonly title: string
  }>
  readonly ' $fragmentType': 'ServiceReportFeatures_features'
}
export type ServiceReportFeatures_features$key = {
  readonly ' $data'?: ServiceReportFeatures_features$data
  readonly ' $fragmentSpreads': FragmentRefs<'ServiceReportFeatures_features'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ServiceReportFeatures_features',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'ServiceReport',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'content_text',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'title',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'ServiceReportResult',
  abstractKey: null
}

;(node as any).hash = '857a18a5fd6042302738744f075a7b19'

export default node
