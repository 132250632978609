/**
 * @generated SignedSource<<13acfbbbc7aeb6ed2a49a2b3b5bfc719>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type DirectorComment_comment$data = {
  readonly cemeteryId: number
  readonly director_comment: string | null
  readonly ' $fragmentType': 'DirectorComment_comment'
}
export type DirectorComment_comment$key = {
  readonly ' $data'?: DirectorComment_comment$data
  readonly ' $fragmentSpreads': FragmentRefs<'DirectorComment_comment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'DirectorComment_comment',
  selections: [
    {
      alias: 'cemeteryId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'director_comment',
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '590b0fcdcc405fb6e03427258a87afc8'

export default node
