import { FC } from 'react'
import { css } from '@emotion/react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { typography } from '@lifedot/styles/typography'
import { palette } from '@lifedot/styles/palette'
import { RelatedPost_posts$key } from './__generated__/RelatedPost_posts.graphql'

const styles = {
  root: css({
    border: `1px solid ${palette.gray2}`,
    padding: '16px 20px'
  }),
  title: css({
    color: palette.gray6
  }),
  list: css({
    marginTop: 4,
    listStyle: 'initial',
    marginLeft: 20
  })
}

interface RelatedPostProps {
  posts: RelatedPost_posts$key
}

export const RelatedPost: FC<RelatedPostProps> = ({ posts }) => {
  const { items } = useFragment(fragment, posts)

  if (items.length < 1) return null
  return (
    <div css={styles.root}>
      <p css={[typography.textM, styles.title]}>この霊園のおすすめ記事</p>
      <ul css={styles.list}>
        {items.map(({ slug, h1 }) => (
          <li key={slug}>
            <Link href={router.post(slug)} passHref prefetch={false}>
              <a css={typography.textM}>{h1}</a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const fragment = graphql`
  fragment RelatedPost_posts on PostResult {
    items {
      h1
      slug
    }
  }
`
