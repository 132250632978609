import { FC } from 'react'
import { css } from '@emotion/react'
import { Label } from '@lifedot/atoms/Label'

const styles = {
  attributes: css({
    fontSize: 0,
    '> *': {
      marginRight: 4
    }
  })
}

interface AttributesProps {
  cemeteryType?: { name: string }
  sect?: { name: string }
}

export const Attributes: FC<AttributesProps> = ({ cemeteryType, sect }) => {
  return (
    <div css={styles.attributes}>
      {cemeteryType && <Label large>{cemeteryType.name}</Label>}
      {sect && <Label large>{sect.name}</Label>}
    </div>
  )
}
