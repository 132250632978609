/**
 * @generated SignedSource<<159da25824d08fa36598e8650ab0c331>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Applications_applications$data = {
  readonly name: string
  readonly applications: {
    readonly accept_start_date: string
    readonly accept_stop_date: string
    readonly accept_sub_info: string
    readonly competitive_ratio: string
    readonly enabled: boolean
    readonly flow: string
    readonly price: string
    readonly requirement: string
    readonly source: string
    readonly subsidies: string
  }
  readonly ' $fragmentType': 'Applications_applications'
}
export type Applications_applications$key = {
  readonly ' $data'?: Applications_applications$data
  readonly ' $fragmentSpreads': FragmentRefs<'Applications_applications'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Applications_applications',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryApplications',
      kind: 'LinkedField',
      name: 'applications',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'accept_start_date',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'accept_stop_date',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'accept_sub_info',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'competitive_ratio',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'enabled',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'flow',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'price',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'requirement',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'source',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'subsidies',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '6dc0911247518b98f99a07011ffebdbd'

export default node
