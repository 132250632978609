import { FC } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { distanceFormatter, makeAccessText } from '@lifedot/utils'
import { typography } from '@lifedot/styles/typography'
import { AccessText_cemetery$key } from './__generated__/AccessText_cemetery.graphql'
import { listPath } from '@lifedot/router'

interface Props {
  cemetery: AccessText_cemetery$key
}

export const AccessText: FC<Props> = ({ cemetery }) => {
  const data = useFragment(fragment, cemetery)
  const {
    name,
    nearest_station: nearestStation,
    prefecture,
    city,
    accesses
  } = data

  return (
    <p css={typography.textM}>
      {name}は、
      <b>
        <a
          href={listPath({
            prefectureRoma: prefecture.roma,
            cityId: city.cityId
          })}
        >
          {prefecture.name}
          {city.name}
        </a>
      </b>
      にあり
      {nearestStation.distance && (
        <>
          、最寄駅の
          <b>
            <a
              href={listPath({
                prefectureRoma: prefecture.roma,
                stationId: nearestStation.stationId
              })}
            >
              {nearestStation.name}駅
            </a>
          </b>
          から
          <b>約{distanceFormatter(nearestStation.distance)}</b>の場所にあり
        </>
      )}
      ます。
      {accesses.other && (
        <>
          <br />
          公共交通機関の場合{makeAccessText(accesses.other)}です。
        </>
      )}
      {accesses.car && (
        <>
          <br />
          車の場合{makeAccessText(accesses.car)}です。
        </>
      )}
    </p>
  )
}

const fragment = graphql`
  fragment AccessText_cemetery on Cemetery {
    name
    nearest_station {
      name
      distance
      stationId: id
    }
    prefecture {
      name
      roma
    }
    city {
      name
      cityId: id
    }
    accesses {
      car
      other
    }
  }
`
