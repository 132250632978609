import { FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { css } from '@emotion/react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Accordion } from '@lifedot/atoms/Accordion'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { PostContent } from '@lifedot/components/PostContent'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { distanceFormatter } from '@lifedot/utils'
import { ShortVoices } from '@/components/Reviews/ShortVoices/ShortVoices'
import { Methods_cemetery$key } from './__generated__/Methods_cemetery.graphql'
import { Methods_access$key } from './__generated__/Methods_access.graphql'
import { useSortedStations, hasTrafficReview } from './dependencies'

const styles = {
  heading: css({
    borderLeft: `4px solid ${palette.main}`,
    paddingLeft: 12
  }),
  text: css({
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  }),
  sub: css({
    background: palette.gray1,
    padding: '12px 20px',
    borderRadius: 4
  }),
  expandIcon: css({
    '[open] &': {
      transform: 'rotate(180deg)'
    }
  }),
  accordionHead: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  section: css({
    display: 'grid',
    gap: 8
  })
}

type MethodsProps = {
  cemetery: Methods_cemetery$key
  access: Methods_access$key
}

export const Methods: FC<MethodsProps> = ({ cemetery, access }) => {
  const data = useFragment(fragment, cemetery)
  const { accesses, stations, flags, reviews } = data
  const sortedStations = useSortedStations(stations)
  const accessData = useFragment(accessFragment, access)
  const isShowShortVoices = hasTrafficReview(reviews)

  return (
    <AddMarginWrapper spacing={6}>
      {!!accesses.other && (
        <section css={styles.section}>
          <h3 css={[typography.textL, styles.heading]}>
            電車・バスでのアクセス方法
          </h3>
          <p css={[typography.textM, styles.text]}>{accesses.other}</p>
          {!!sortedStations.length && (
            <section css={styles.sub}>
              <Accordion
                title={
                  <h4 css={[typography.textM, styles.accordionHead]}>
                    近くにある駅
                    <ExpandMoreIcon css={styles.expandIcon} />
                  </h4>
                }
              >
                <ul>
                  {sortedStations.map((station) => (
                    <li key={station.name} css={typography.textS}>
                      {station.railwayLineName}&emsp;
                      {station.name}駅（
                      {distanceFormatter(station.distance)}）
                    </li>
                  ))}
                </ul>
              </Accordion>
            </section>
          )}
          {!!accesses.taxi && (
            <section css={styles.sub}>
              <h4 css={typography.textM}>周辺で利用可能なタクシー会社</h4>
              <p css={[typography.textS, styles.text]}>{accesses.taxi}</p>
            </section>
          )}
        </section>
      )}
      {!!accesses.shuttle_bus && (
        <section css={styles.section}>
          <h3 css={[typography.textL, styles.heading]}>
            送迎バスでのアクセス方法
          </h3>
          <p css={[typography.textM, styles.text]}>{accesses.shuttle_bus}</p>
        </section>
      )}
      {!!accesses.car && (
        <section css={styles.section}>
          <h3 css={[typography.textL, styles.heading]}>車でのアクセス方法</h3>
          <p css={[typography.textM, styles.text]}>{accesses.car}</p>
          <div css={styles.sub}>
            <h4 css={typography.textM}>駐車場</h4>
            <p css={typography.textS}>{flags.parking ? 'あり' : 'なし'}</p>
          </div>
        </section>
      )}
      {accessData.items.map(({ content_text, title }, index) => (
        <section css={styles.section} key={index}>
          <h3 css={[typography.textL, styles.heading]}>{title}</h3>
          <PostContent contentHtml={content_text} />
        </section>
      ))}
      {isShowShortVoices && (
        <section css={styles.section}>
          <h3 css={[typography.textL, styles.heading]}>
            交通アクセスに関する利用者の声
          </h3>
          <ShortVoices cemetery={data} />
        </section>
      )}
    </AddMarginWrapper>
  )
}

const fragment = graphql`
  fragment Methods_cemetery on Cemetery {
    ...ShortVoices_cemetery
    accesses {
      car
      taxi
      shuttle_bus
      other
    }
    stations {
      distance
      name
      railway_line_name
    }
    flags {
      parking
    }
    reviews {
      answers {
        code
        text
      }
    }
  }
`

const accessFragment = graphql`
  fragment Methods_access on ServiceReportResult {
    items {
      content_text
      title
    }
  }
`
