/**
 * @generated SignedSource<<bd7d56f4cfa76a042bea5faf6cbc1bea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type useFaqs_successor$data = {
  readonly cemeteryId: number
  readonly flags: {
    readonly successor_unnecessary: boolean
  }
  readonly choices: ReadonlyArray<{
    readonly code: string
  }>
  readonly prefecture: {
    readonly roma: string
  }
  readonly city: {
    readonly cityId: number
    readonly name: string
  }
  readonly name: string
  readonly ' $fragmentType': 'useFaqs_successor'
}
export type useFaqs_successor$key = {
  readonly ' $data'?: useFaqs_successor$data
  readonly ' $fragmentSpreads': FragmentRefs<'useFaqs_successor'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'useFaqs_successor',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryFlags',
        kind: 'LinkedField',
        name: 'flags',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'successor_unnecessary',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'choices',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'code',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'roma',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryCity',
        kind: 'LinkedField',
        name: 'city',
        plural: false,
        selections: [
          {
            alias: 'cityId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      },
      v0 /*: any*/
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = 'd8af0319cae8c3bc95933d547ab8e820'

export default node
