/**
 * @generated SignedSource<<bfef6112f92dbf389cc35e0d40e25b52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Appeal_appeals$data = {
  readonly descriptions: ReadonlyArray<{
    readonly body: string | null
    readonly subject: string | null
  }>
  readonly ' $fragmentType': 'Appeal_appeals'
}
export type Appeal_appeals$key = {
  readonly ' $data'?: Appeal_appeals$data
  readonly ' $fragmentSpreads': FragmentRefs<'Appeal_appeals'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Appeal_appeals',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryDescription',
      kind: 'LinkedField',
      name: 'descriptions',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'body',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'subject',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = 'dd67f237889bcfdcbe50e3a4fb0dee71'

export default node
