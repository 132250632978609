import { FC, Fragment } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { PostContent } from '@lifedot/components/PostContent'
import { ServiceReportFeatures_features$key } from './__generated__/ServiceReportFeatures_features.graphql'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'

const styles = {
  content: css({
    marginTop: 16
  })
}

interface ServiceReportFeaturesProps {
  features: ServiceReportFeatures_features$key
}

export const ServiceReportFeatures: FC<ServiceReportFeaturesProps> = ({
  features
}) => {
  const data = useFragment(fragment, features)

  return (
    <>
      {data.items.map(({ content_text, title }, index) => (
        <Fragment key={index}>
          <h3 css={typography.textXL}>{title}</h3>
          <div css={[typography.textM, styles.content]}>
            <PostContent contentHtml={content_text} />
          </div>
        </Fragment>
      ))}
    </>
  )
}

const fragment = graphql`
  fragment ServiceReportFeatures_features on ServiceReportResult {
    items {
      content_text
      title
    }
  }
`
