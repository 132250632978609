/**
 * @generated SignedSource<<8bc9503c585ec40043c7fed49db7c073>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime'
export type CemeterySortInput = {
  field: string
  order: string
}
export type cemeteryPage_buildTargetsQuery$variables = {
  size: number
  from: number
  sort?: CemeterySortInput | null
}
export type cemeteryPage_buildTargetsQuery$data = {
  readonly cemeteries: {
    readonly items: ReadonlyArray<{
      readonly cemeteryId: number
    }>
    readonly pagination: {
      readonly hasNext: boolean
    }
  }
}
export type cemeteryPage_buildTargetsQuery = {
  variables: cemeteryPage_buildTargetsQuery$variables
  response: cemeteryPage_buildTargetsQuery$data
}

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'from'
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'size'
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'sort'
    },
    v3 = [
      {
        alias: 'cemeteries',
        args: [
          {
            kind: 'Literal',
            name: 'filter',
            value: {}
          },
          {
            fields: [
              {
                kind: 'Variable',
                name: 'from',
                variableName: 'from'
              },
              {
                kind: 'Variable',
                name: 'size',
                variableName: 'size'
              }
            ],
            kind: 'ObjectValue',
            name: 'page'
          },
          {
            kind: 'Variable',
            name: 'sort',
            variableName: 'sort'
          }
        ],
        concreteType: 'CemeteryResult',
        kind: 'LinkedField',
        name: 'listCemeteries',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Cemetery',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: 'cemeteryId',
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'PaginationStatus',
            kind: 'LinkedField',
            name: 'pagination',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'hasNext',
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ]
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'cemeteryPage_buildTargetsQuery',
      selections: v3 /*: any*/,
      type: 'Query',
      abstractKey: null
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/, v2 /*: any*/],
      kind: 'Operation',
      name: 'cemeteryPage_buildTargetsQuery',
      selections: v3 /*: any*/
    },
    params: {
      cacheID: '82d0f7d1c30d434d4a10bb5b9c9f4f9d',
      id: null,
      metadata: {},
      name: 'cemeteryPage_buildTargetsQuery',
      operationKind: 'query',
      text: 'query cemeteryPage_buildTargetsQuery(\n  $size: Int!\n  $from: Int!\n  $sort: CemeterySortInput\n) {\n  cemeteries: listCemeteries(filter: {}, page: {size: $size, from: $from}, sort: $sort) {\n    items {\n      cemeteryId: id\n    }\n    pagination {\n      hasNext\n    }\n  }\n}\n'
    }
  }
})()

;(node as any).hash = 'ac41823a12151b24db6064efd40a2429'

export default node
