import { distanceFormatter } from '@lifedot/utils'
import { FC } from 'react'
import { css } from '@emotion/react'
import { Overview_cemetery$data } from './__generated__/Overview_cemetery.graphql'
import TrainIcon from '@material-ui/icons/Train'
import RoomIcon from '@material-ui/icons/Room'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { elevation } from '@lifedot/styles/elevation'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import Image from 'next/image'
import Link from 'next/link'
import { router } from '@lifedot/router'

const styles = {
  wrap: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  icon: css({
    color: palette.gray4,
    verticalAlign: 'bottom'
  }),
  link: css({
    boxShadow: elevation[1],
    border: `1px solid ${palette.gray2}`,
    borderRadius: 4,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: palette.main03
  }),
  img: css({
    width: 48,
    fontSize: 0
  }),
  access: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 10
  })
}

interface AccessProps {
  cemeteryId: number
  nearestStation: Overview_cemetery$data['nearest_station']
  address: Overview_cemetery$data['address']
}

export const Access: FC<AccessProps> = ({
  cemeteryId,
  nearestStation,
  address
}) => {
  return (
    <div css={styles.wrap}>
      <div>
        {nearestStation.name !== null && nearestStation.distance !== null ? (
          <p css={typography.textM}>
            <TrainIcon css={styles.icon} />
            <b>
              {nearestStation.name}駅から
              {distanceFormatter(nearestStation.distance)}
            </b>
          </p>
        ) : null}
        {address && (
          <p css={typography.textM}>
            <RoomIcon css={styles.icon} />
            {address}
          </p>
        )}
      </div>
      <Link href={router.access(cemeteryId)} prefetch={false} passHref>
        <a css={styles.link}>
          <figure css={styles.img}>
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/67770/icon_map.png"
              width={120}
              height={80}
              alt=""
            />
          </figure>
          <span css={styles.access}>
            行き方
            <ExpandMoreIcon style={{ fontSize: 14 }} />
          </span>
        </a>
      </Link>
    </div>
  )
}
